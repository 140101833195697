/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import {
    Button, ConfirmationButton, ConfirmationChildren,
    formElementSizesEnum as FormElementSizes, InitialChildren,
    Table,
    TableBody,
    TableBodyData,
    TableBodyDataCellTemplate,
    TableBodyDataRowTemplate,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    TableWrapper
} from '@utahdts/utah-design-system';
import React from "react";
import {useApi} from "../hooks/useApi.js";

const propTypes = {
    tableName: PropTypes.string.isRequired,
    tableList: PropTypes.arrayOf(PropTypes.shape({
        authorizationId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
    })),

};
const defaultProps = {};

function CoapplicantCard({ tableName, tableList, onEdit, onDelete }) {

    const apiAddr = useApi();
    const handleEdit = (coapplicantId) => {
        if (onEdit) {
            onEdit(coapplicantId);
        }
    }

    const handleConfirmDelete = (coapplicantId) => {
        // Implement delete functionality based on the passed id
        if (onDelete) {
            apiAddr.delete(`/coapplicant/coapplicant-delete/${coapplicantId}`)
                .then(() => {
                    onDelete();
                });
        }
    };

    return (
        tableList
            ? (
                <TableWrapper id={`${tableName}-table`}>
                    <Table className="table table--lines-x table--alt">
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell recordFieldPath="firstName">First Name</TableHeadCell>
                                <TableHeadCell recordFieldPath="dateOfBirth">Date of Birth</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyData records={tableList} recordIdField="authorizationId">
                                <TableBodyDataRowTemplate>
                                    <TableBodyDataCellTemplate recordFieldPath="firstName" />
                                    <TableBodyDataCellTemplate recordFieldPath="dateOfBirth" />
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <Button
                                                    appearance="solid"
                                                    color="none"
                                                    iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                                    id={`button-card-${record.coapplicantId}-id`}
                                                    className="button--secondary-color"
                                                    onClick={() => {handleEdit(record.coapplicantId)}}
                                                    size={FormElementSizes.SMALL}
                                                >
                                                    Edit
                                                </Button>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <ConfirmationButton
                                                    appearance="outlined"
                                                    color="primary"
                                                    confirmationColor={"accent"}
                                                    id={`button-card-${record.coapplicantId}-id`}
                                                    onClick={() => {handleConfirmDelete(record.coapplicantId)}}
                                                    size={FormElementSizes.SMALL}
                                                >
                                                    <InitialChildren>Delete</InitialChildren>
                                                    <ConfirmationChildren>Are you sure?</ConfirmationChildren>
                                                </ConfirmationButton>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                </TableBodyDataRowTemplate>
                            </TableBodyData>
                        </TableBody>
                    </Table>
                </TableWrapper>
            )
            : ''
    );
}

CoapplicantCard.propTypes = propTypes;
CoapplicantCard.defaultProps = defaultProps;

export default CoapplicantCard;

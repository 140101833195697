/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    TableWrapper,
    Table,
    TableHead,
    TableHeadRow,
    TableHeadCell,
    TableBody,
    TableBodyData,
    TableBodyDataRowTemplate,
    TableBodyDataCellTemplate, Button,
} from '@utahdts/utah-design-system';
import React from "react";
import {useNavigate} from "react-router-dom";
import routingUrls from "../enum/routingUrls.js";

const propTypes = {
    dmvList: PropTypes.arrayOf(PropTypes.shape({
        vehicleId: PropTypes.number.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        plateNumber: PropTypes.string,
        vin: PropTypes.string,
    })),

};
const defaultProps = {};

function Dmv({
                 dmvList,
             }) {
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate(routingUrls.options);
    };

    return (
        dmvList.length
            ? (
                <>
                    <div className="application-title">
                        <h4>DMV Information</h4>
                    </div>
                    <div className="application__vertical">
                        <TableWrapper id="dmv-table">
                            <Table className="table--lines-x table--alt table--full-width mb-spacing">
                                <TableHead>
                                    <TableHeadRow>
                                        <TableHeadCell recordFieldPath="firstName">First Name</TableHeadCell>
                                        <TableHeadCell recordFieldPath="lastName">Last Name</TableHeadCell>
                                        <TableHeadCell recordFieldPath="plateNumber">Plate Number</TableHeadCell>
                                        <TableHeadCell recordFieldPath="vin">VIN</TableHeadCell>
                                    </TableHeadRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyData records={dmvList} recordIdField="vehicleId">
                                        <TableBodyDataRowTemplate>
                                            <TableBodyDataCellTemplate recordFieldPath="firstName" />
                                            <TableBodyDataCellTemplate recordFieldPath="lastName" />
                                            <TableBodyDataCellTemplate recordFieldPath="plateNumber" />
                                            <TableBodyDataCellTemplate recordFieldPath="vin" />
                                        </TableBodyDataRowTemplate>
                                    </TableBodyData>
                                </TableBody>
                            </Table>
                        </TableWrapper>
                        <div className="application__vertical-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
            : (
                <>
                    <div className="application-title">
                        <h4>DMV Information</h4>
                    </div>
                    <div className="application__group">
                        <p>No Records Found</p>
                        <div className="application__group-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
    );
}

Dmv.propTypes = propTypes;
Dmv.defaultProps = defaultProps;

export default Dmv;

/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { setUtahHeaderSettings } from '@utahdts/utah-design-system-header';
import {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import routingUrls from "../enum/routingUrls.js";
import {AddrConfUserStatus, useAddrConfUser} from "./useAddrConfUser.js";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useOidc} from "@axa-fr/react-oidc";

function actionFunctionForUrl(url, navigate) {
    navigate(url);
}

export default function useUtahHeader() {
    const navigate = useNavigate();
    const {login, logout, isAuthenticated} = useOidc();
    const { addrConfUser, addrConfUserLoadingState } = useAddrConfUser();
    const { setAppState } = useContext(AppIdContext);

    useEffect(
        () => {
            setUtahHeaderSettings(
                {
                    logo: {
                        element: () => {
                            const logo = document.createElement('img');
                            logo.src = 'LogoColor.svg';
                            return logo;
                        }
                    },
                    mediaSizes: {
                        mobile: 640,
                        tabletPortrait: 768,
                        tabletLandscape: 1024,
                    },
                    mainMenu: {
                        menuItems: [
                            {
                                actionFunctionUrl: {
                                    url: routingUrls.landing,
                                    actionFunction: () => {
                                        sessionStorage.removeItem('appId');
                                        setAppState((appState) => {appState.returnReview = 0;});
                                        actionFunctionForUrl(routingUrls.landing, navigate)
                                    },
                                },
                                title: 'Home'
                            },
                        ],
                    },
                    title: 'Safe At Home',
                    titleUrl: routingUrls.landing,
                    size: 'MEDIUM',

                    utahId: {
                        onSignIn: () => login(routingUrls.home),
                        onSignOut: () => logout(routingUrls.logout),

                        currentUser: (
                            addrConfUserLoadingState === AddrConfUserStatus.Loaded
                                ? {
                                    authenticated: true,
                                    first: addrConfUser?.name?.split(' ')?.[0] || '',
                                }
                                : null
                        ),
                    },
                    skipLinkUrl: '#root',
                });

            setAppState((draftAppState) => {
                draftAppState.addrConfUser = addrConfUser;
                draftAppState.addrConfUserLoadingState = addrConfUserLoadingState;
            });
        },
        [isAuthenticated, addrConfUserLoadingState]
    );
}

const routingUrls = {
    home: '/',
    landing: '/landingPg',
    info: '/applicationInfoPg/',
    test: '/fileTest',
    address: '/addressPg',
    file: '/fileSubmit',
    affirm: '/affirmationPg',
    comp: '/completionPg',
    rec: '/assistantRecPg',
    review: '/reviewPg',
    coapp: '/coapplicantPg',
    court: '/courtOrderPg',
    options: '/additionalOptionsPg',
    logout: 'https://safeathome.utah.gov/',
};

export default routingUrls;

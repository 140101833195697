/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    TextInput, Form, Button,  ComboBox
} from '@utahdts/utah-design-system';
import {useNavigate} from "react-router-dom";
import routingUrls from "../enum/routingUrls.js";
import ComboBoxOptions from "./ComboBoxOptions.jsx";
import React from "react";

const propTypes = {
    address: PropTypes.shape({
        addressTypeId: PropTypes.number,
        streetName: PropTypes.string,
        streetName2: PropTypes.string,
        streetUnitNumber: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        county: PropTypes.any,
        isActive: PropTypes.number,
    }),
    title: PropTypes.string,
    path: PropTypes.string,
    countyList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        isActive: PropTypes.number,
    })),
    state: PropTypes.object,
    setState: PropTypes.func,

};
const defaultProps = {};

function ApplicantAddress({
                              address,
                              title,
                              path,
                              countyList,
                              state,
                              setState,
                          }) {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(routingUrls.address);
    };

    return (
        address
            ? (
                <div key={`address-${address.applicantAddressId}`}>
                    <div className="application-title">
                        <h4>{title}</h4>
                    </div>
                    <Form id={`address-form-${address.applicantAddressId}`} state={state} setState={setState}>
                        <div className="application__review-group">
                            <TextInput
                                id={`${path}.streetName`}
                                label="Street Address"
                                isDisabled={true}
                                value={address?.streetName}
                            />
                            <TextInput
                                id={`${path}.streetName2`}
                                label="Street Address 2"
                                isDisabled={true}
                                value={address?.streetName2}
                            />
                            <TextInput
                                id={`${path}.city`}
                                label="City"
                                isDisabled={true}
                                value={address?.city}
                            />
                            <TextInput
                                id={`${path}.state`}
                                label="State"
                                isDisabled={true}
                                value={address?.state}
                            />
                            <TextInput
                                id={`${path}.zip`}
                                label="Zip"
                                isDisabled={true}
                                value={address?.zip}
                            />
                            <div hidden={address.addressTypeId === 2}>
                                <ComboBox
                                    id={`${path}.county`}
                                    name={"county"}
                                    label={"County"}
                                    placeholder={"Search or Select..."}
                                    isDisabled={true}
                                    value={address?.county}
                                >
                                    <ComboBoxOptions optionName={"counties"} value={countyList}/>
                                </ComboBox>
                            </div>
                            <div className="application__group-bottom-right">
                                <Button
                                    iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                    appearance="solid"
                                    color="secondary"
                                    id="button-applicant"
                                    onClick={handleSubmit}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            )
            : (
                <>
                    <div className="application-title">
                        <h4>{title}</h4>
                    </div>
                    <div className={"application__group"}>
                        <p>No Records Found</p>
                        <div className="application__group-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleSubmit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
    );
}

ApplicantAddress.propTypes = propTypes;
ApplicantAddress.defaultProps = defaultProps;

export default ApplicantAddress;

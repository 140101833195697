export function fileValidate(){
    let fileInput =  document.getElementById('files');
    let filePath = fileInput.value;
    const allowedExtensions = /(.jpg|\.jpeg|\.png|\.gif|\.pdf|\.svg|\.webp|\.heic|\.docx|\.doc)$/i;
    const fileSizeLimit = 10_000_000;


    if(!allowedExtensions.exec(filePath)){
        alert('Invalid file type');

        return false;
    }
    else if(fileSizeLimit < fileInput.files[0].size ){
        alert('File size limit 10 megabytes');
        return false;
    }
    else if(fileInput.files[0].name.length > 45){
        alert('File name is too long, 45 characters is the size limit.');
    }
    else{
        return true;
    }
}


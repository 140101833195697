import {useApi} from "../hooks/useApi.js";
import React, {useContext, useEffect, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useImmer} from "use-immer";
import apiUrls from "../enum/apiUrls.js";
import {Button, Form, Select, Spinner, TextInput, useBanner} from "@utahdts/utah-design-system";
import {PatternFormat} from "react-number-format";
import SelectOptions from "../elements/SelectOptions.jsx";
import CoapplicantCard from "../elements/CoapplicantCard";
import AppNavigation from "../elements/AppNavigation.jsx";
import routingUrls from "../enum/routingUrls.js";
import {buildBanner} from "../services/bannerService.jsx";
import {coapplicantSchema} from "../services/validationService.js";


function CoapplicantPg() {

    const apiAddr = useApi();
    const { addBanner } = useBanner();
    const { appState: { appId, returnReview  } } = useContext(AppIdContext);
    const [genderList, setGenderList] = useState([]);
    const [pronounList, setPronounList] = useState([]);
    const [coapplicantList, setCoapplicantList] = useImmer([]);
    const [loading, setLoading] = useState(true);
    const [coapplicant, setCoapplicant] = useImmer({
        coapplicantId:"",
        applicantApplicationId: appId,
        authorizationId: "",
        firstName:"",
        middleName:"",
        lastName:"",
        dateOfBirth:"",
        relationshipToApplicant:"",
        gender:0,
        pronoun:0,
        phone:"",
        email:"",
        signature:"",
    });

    const initialState  = {
        coapplicantId:"",
        applicantApplicationId: appId,
        authorizationId: "",
        firstName:"",
        middleName:"",
        lastName:"",
        dateOfBirth:"",
        relationshipToApplicant:"",
        gender:0,
        pronoun:0,
        phone:"",
        email:"",
        signature:"",
    };

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    useEffect(() => {
        (async () => {
            try {
                const [
                    genderResponse,
                    pronounResponse,
                    coapplicantResponse,
                ] = await Promise.all([
                    apiAddr.get(apiUrls.genderList),
                    apiAddr.get(apiUrls.pronounList),
                    apiAddr.get(apiUrls.coAppList+appId),
                ]);
                setGenderList(genderResponse.data);
                setPronounList(pronounResponse.data);
                setCoapplicantList(coapplicantResponse.data);
                setLoading(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        })();
        // eslint-disable-next-line
    },[]);

    const handleChange = (name, value) => {
        setCoapplicant({...coapplicant, [name] : Array.isArray(value) ? [...value] : value});
    }

    const handleEdit = (coapplicantId) => {
        apiAddr.get('/coapplicant/'+coapplicantId)
            .then(response => {
                setCoapplicant(response.data);
            });
    }

    const handleDelete = () => {
        apiAddr.get(apiUrls.coAppList+appId)
            .then(response => {
                setCoapplicantList(response.data);
            });
    }

    const handleAdd = async () => {
        try {
            await coapplicantSchema.validate(coapplicant);
            await apiAddr.post(`/coapplicant/insert-new`, JSON.stringify(coapplicant))
                .then(() => {
                    apiAddr.get(apiUrls.coAppList + appId)
                        .then(response => {
                            setCoapplicantList(response.data);
                            setCoapplicant(initialState);
                        });
                });
        } catch (error) {
            errorBanner(error.message)
        }
    }

    const handleClear = () => { setCoapplicant(initialState);}

    function countList(list) {
        return list ? list.length : 0;
    }

    return(
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Co-Applicant Page
                                </h1>
                                <br />
                                <p className={"application__vertical-max50"}>All members of the victim’s household are eligible to enroll as co-applicants. This
                                    includes children, other family members, and roommates. All co-applicants who are 18 or older will need to sign the application.</p>
                                <div className={"application__group12"}>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Co-Applicant</h4>
                                        </div>
                                        <Form id={"coapplicant"}
                                              state={coapplicant}
                                              setState={setCoapplicant}
                                              autoComplete={"hidden"}
                                        >
                                            <div className={"application__vertical"}>
                                                <TextInput
                                                    id={"firstName"}
                                                    name={"firstName"}
                                                    label={"First Name"}
                                                    isDisabled={false}
                                                    maxLength={30}
                                                    value={coapplicant?.firstName}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id="middleName"
                                                    name={"middleName"}
                                                    label="Middle Name"
                                                    isDisabled={false}
                                                    maxLength={30}
                                                    value={coapplicant?.middleName}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id="lastName"
                                                    name={"lastName"}
                                                    label="Last Name"
                                                    isDisabled={false}
                                                    maxLength={30}
                                                    value={coapplicant?.lastName}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                <div className={"input-wrapper input-wrapper--text-input"}>
                                                    <label>Date Of Birth<span className={"required-star"}>*</span></label>
                                                    <PatternFormat format={"##/##/####"}
                                                                   placeholder={"mm/dd/yyyy"}
                                                                   className={"PatternFormat"}
                                                                   name={"dateOfBirth"}
                                                                   onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                                   value={coapplicant?.dateOfBirth}
                                                                   autoComplete="off"
                                                    />
                                                </div>
                                                <div className={"application__vertical-div"}>
                                                    <label>Phone (if an adult)</label>
                                                    <PatternFormat format={"(###) ###-####"}
                                                                   placeholder={"(###) ###-####"}
                                                                   className={"PatternFormat"}
                                                                   name={"phone"}
                                                                   onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                                   value={coapplicant?.phone}
                                                                   autoComplete="off"
                                                    />
                                                </div>
                                                <TextInput
                                                    id="email"
                                                    name={"email"}
                                                    label="Email (if an adult)"
                                                    isDisabled={false}
                                                    value={coapplicant?.email}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id="relationshipToApplicant"
                                                    name={"relationshipToApplicant"}
                                                    label="Relationship To Applicant"
                                                    isDisabled={false}
                                                    maxLength={50}
                                                    isRequired
                                                    value={coapplicant?.relationshipToApplicant}
                                                    autoComplete="off"
                                                />
                                                <Select
                                                    id="gender"
                                                    name={"gender"}
                                                    label="Gender"
                                                    isDisabled={false}
                                                    value={coapplicant?.gender.value}
                                                    autoComplete="off"
                                                >
                                                    <SelectOptions optionName="genderOptions" value={genderList} />
                                                </Select>
                                                <Select
                                                    id="pronoun"
                                                    name={"pronoun"}
                                                    label="Pronouns"
                                                    isDisabled={false}
                                                    value={coapplicant?.pronoun.value}
                                                    autoComplete="off"
                                                >
                                                    <SelectOptions optionName="pronounOptions" value={pronounList} />
                                                </Select>
                                                <div className={"application__coapp-buttons"}>
                                                    <div className="application__coapp-buttons-left">
                                                        <Button
                                                            appearance="outlined"
                                                            color="primary"
                                                            id="button-applicant"
                                                            onClick={handleClear}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                    <div className="application__coapp-buttons-right">
                                                        <Button
                                                            iconRight={<span className={`utds-icon-before-plus`} aria-hidden="true" />}
                                                            appearance="solid"
                                                            color="secondary"
                                                            id="button-applicant"
                                                            onClick={handleAdd}
                                                        >
                                                            {coapplicant.coapplicantId ? "Update" : "Add"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div>
                                        <div className={"card-section"}>
                                            <div className={"card-section__card card-section__card-wide"}>
                                                <div className={"card-section__card-title"}>
                                                    <span className={`utds-icon-before-light-mode`} aria-hidden="true" />
                                                    <h3>Co-Applicants</h3>
                                                </div>
                                                <div className={"card-section__card-count"}>
                                                    {countList(coapplicantList)}
                                                </div>
                                                {
                                                    coapplicantList?.length
                                                        ?(
                                                            <CoapplicantCard
                                                                tableName={"Coapplicant"}
                                                                tableList={coapplicantList}
                                                                onEdit={handleEdit}
                                                                onDelete={handleDelete}
                                                            />
                                                        )
                                                        : <div className={"card-section__card-footer"}>No Records Match Criteria</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            onNext={returnReview === 1 ? routingUrls.review : routingUrls.court}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            onBack={routingUrls.address}
                            backLabel={"Back"}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                        />
                    </main>
                </div>
            )
    )

}

export default CoapplicantPg;

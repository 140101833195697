/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import {
    formElementSizesEnum, IconButton,
    Table,
    TableBody,
    TableBodyData,
    TableBodyDataCellTemplate,
    TableBodyDataRowTemplate,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    TableWrapper
} from '@utahdts/utah-design-system';
import React from "react";
import {useApi} from "../hooks/useApi.js";

const propTypes = {
    tableName: PropTypes.string.isRequired,
    tableList: PropTypes.arrayOf(PropTypes.shape({
        offenseDocumentId: PropTypes.number.isRequired,
        docAttributes: PropTypes.string.isRequired,
    })),

};
const defaultProps = {};

function FileCard({ tableName, tableList, onDelete }) {

    const apiAddr = useApi();

    const handleConfirmDelete = (offenseDocumentId) => {
        // Implement delete functionality based on the passed id
        if (onDelete) {
            apiAddr.delete(`/offense-document/delete/${offenseDocumentId}`)
                .then(() => {
                    onDelete();
                });
        }
    };

    return (
        tableList
            ? (
                <TableWrapper id={`${tableName}-table`}>
                    <Table className="table table--lines-x table--alt">
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell recordFieldPath="docAttributes">File Name</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyData records={tableList} recordIdField="offenseDocumentId">
                                <TableBodyDataRowTemplate>
                                    <TableBodyDataCellTemplate recordFieldPath="docAttributes" />
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <IconButton
                                                    appearance="outlined"
                                                    color="primary"
                                                    icon={<span className={`utds-icon-before-trash`} aria-hidden="true" />}
                                                    id={`button-card-${record.offenseDocumentId}-id`}
                                                    onClick={() => {handleConfirmDelete(record.offenseDocumentId)}}
                                                    size={formElementSizesEnum.SMALL}
                                                    title="Delete File"
                                                />
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                </TableBodyDataRowTemplate>
                            </TableBodyData>
                        </TableBody>
                    </Table>
                </TableWrapper>
            )
            : ''
    );
}

FileCard.propTypes = propTypes;
FileCard.defaultProps = defaultProps;

export default FileCard;

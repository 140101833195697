/* eslint-disable react/jsx-one-expression-per-line */
import {
    Select,
    TextInput,
    Form,
    MultiSelect,
    MultiSelectOption,
    Button, DateInput
} from '@utahdts/utah-design-system';
import SelectOptions from './SelectOptions.jsx';
import {useNavigate} from "react-router-dom";
import routingUrls from "../enum/routingUrls.js";
import {removeTime} from "../services/dateService.js";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
    applicant: PropTypes.objectOf(PropTypes.shape({
        applicationId: PropTypes.string.isRequired,
        programAssistantId: PropTypes.number.isRequired,
        applicationStatusId: PropTypes.number.isRequired,
        gender: PropTypes.number.isRequired,
        pronoun: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        enrollmentReasons: PropTypes.string,
        safeLabelName: PropTypes.string,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        preferredLanguage: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        signature: PropTypes.string,
        isComplete: PropTypes.number,
        isActive: PropTypes.number,
    })),
    genderList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        isActive: PropTypes.number,
    })),
    pronounList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        isActive: PropTypes.number,
    })),
    enrollmentReasonList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        isActive: PropTypes.number,
    })),
    state: PropTypes.object,
    setState: PropTypes.func,

};
const defaultProps = {};

function Applicant(
    {
        applicant,
        genderList,
        pronounList,
        enrollmentReasonList,
        state,
        setState,
    }
) {
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(routingUrls.info);

    };

    return (
        <div>
            <div className="application-title">
                <h4>Applicant</h4>
            </div>
            <Form id="applicant" state={state} setState={setState}>
                <div className="application__group">
                    <TextInput
                        id="applicant.firstName"
                        label="First Name"
                        isDisabled={true}
                        value={applicant?.firstName}
                    />
                    <TextInput
                        id="applicant.middleName"
                        label="Middle Name"
                        isDisabled={true}
                        value={applicant?.middleName}
                    />
                    <TextInput
                        id="applicant.lastName"
                        label="Last Name"
                        isDisabled={true}
                        value={applicant?.lastName}
                    />
                    <TextInput
                        id="applicant.phone"
                        label="Phone"
                        isDisabled={true}
                        value={applicant?.phone}
                    />
                    <DateInput
                        id="applicant.dateOfBirth"
                        dateFormat="MM/dd/yyyy"
                        label="Date of Birth"
                        isDisabled={true}
                        value={removeTime(applicant?.dateOfBirth)}
                    />
                    <TextInput
                        id="applicant.safeLabelName"
                        label="Safe Label Name"
                        isDisabled={true}
                        value={applicant?.safeLabelName}
                    />
                    {state?.legalNames.map((legalName, index) => (
                        <div key={legalName.id}>
                        <TextInput
                            id={legalName?.id}
                            name={`legalNames-${index}`}
                            label="Former Legal Name"
                            value={legalName?.value}
                            isDisabled
                        />
                        </div>
                    ))
                    }
                    <TextInput
                        id="applicant.email"
                        label="Email"
                        isDisabled={true}
                        value={applicant?.email}
                    />
                    <TextInput
                        id="applicant.preferredLanguage"
                        label="Preferred Language"
                        isDisabled={true}
                        value={applicant?.preferredLanguage}
                    />
                    <TextInput
                        id="applicant.signature"
                        label="Signature"
                        isDisabled
                        value={applicant?.signature}
                    />
                    <Select
                        id="applicant.gender"
                        label="Gender"
                        isDisabled={true}
                        value={applicant?.gender}
                    >
                        <SelectOptions optionName="genderOptions" value={genderList} />
                    </Select>
                    <Select
                        id="applicant.pronoun"
                        label="Pronouns"
                        isDisabled={true}
                        value={applicant?.pronoun}
                    >
                        <SelectOptions optionName="pronounOptions" value={pronounList} />
                    </Select>
                    <MultiSelect
                        id="applicant.enrollmentReasons"
                        label="Enrollment Reason"
                        isDisabled
                        values={state?.enrollmentReasons}
                    >
                        {enrollmentReasonList?.map((item) => (
                            <MultiSelectOption
                                key={`enrollmentReason-${item.id}`}
                                value={item.id}
                                label={item.value}
                            />
                        ))}
                    </MultiSelect>
                    <div className="application__group-bottom-right">
                        <Button
                            iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                            appearance="solid"
                            color="secondary"
                            id="button-applicant"
                            onClick={handleSubmit}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

Applicant.propTypes = propTypes;
Applicant.defaultProps = defaultProps;

export default Applicant;

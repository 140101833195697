export const configuration = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        scope: "phone openid profile directory email",
        authority: "https://login.dts.utah.gov/sso/oauth2",
        // silent_redirect_uri: window.location.origin + "/authentication/silent-callback" ,
        service_worker_relative_url: "/OidcServiceWorker.js",
        service_worker_only: true,
       //  demonstrating_proof_of_possession: true,
    };


import '../App.css';

import '@utahdts/utah-design-system-header/css';
import React, {useContext, useEffect, useState} from 'react';
import AffirmationQuestionList from "../elements/AffirmationQuestionsFetch";
import routingUrls from "../enum/routingUrls.js";
import {useApi} from "../hooks/useApi.js";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import apiUrls from "../enum/apiUrls.js";
import {Spinner, TextInput, useBanner} from "@utahdts/utah-design-system";
import {useImmer} from "use-immer";
import Signature from "../elements/Signature.jsx";
import AppNavigation from "../elements/AppNavigation.jsx";
import {buildBanner} from "../services/bannerService.jsx";
import {applicantSignatureSchema, coapplicantSignatureSchema} from "../services/validationService.js";
import {useNavigate} from "react-router-dom";

function AffirmationPg(){
    const { appState: { appId, returnReview  } } = useContext(AppIdContext);
    const apiAddr = useApi();
    const navigate = useNavigate();
    const { addBanner } = useBanner();
    const [loading, setLoading] = useState(true);
    const [coAppList, setCoAppList] = useImmer([]);
    const [applicant, setApplicant] = useImmer({
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        dateOfBirth: "",
        formerLegalName: "",
        safeLabelName: "",
        email: "",
        preferredLanguage: "",
        gender: "",
        pronoun: "",
        enrollmentReasons: [],
        applicantSignature: ""
    });
    const [checked, setChecked] = useState(false);
    const [questionsList, setQuestionsList] = useState([]);

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    const allChecked = (isChecked) => {
        setChecked(isChecked);
    }

    const handleCoAppSigChange = (index, value) => {
        const newCoApp = [...coAppList];
        newCoApp[index].signature = value;
        setCoAppList(newCoApp);
    }

    const handleSigChange = (name,value) => {
        setApplicant({...applicant,[name]:value})
    }

    const handleSubmit = async () => {
        try {
            await applicantSignatureSchema.validate(applicant);
            await apiAddr.patch(apiUrls.signature, JSON.stringify(applicant))
                .then(() => {
                })
            await coapplicantSignatureSchema.validate(coAppList);
            await apiAddr.patch(apiUrls.coAppListUpdate, JSON.stringify(coAppList))
                .then(() => {
                })
            navigate(returnReview === 1 ? routingUrls.review : routingUrls.rec);
        } catch (errors) {
            errorBanner(errors.message);
        }
    }

    useEffect(()=> {
        (async  () => {
            try {
                const [
                    coAppResponse,
                    applicationResponse,
                    questionResponse,
                ] = await Promise.all([
                    apiAddr.get(apiUrls.coAppList+appId),
                    apiAddr.get(apiUrls.applicationGetById+appId),
                    apiAddr.get(apiUrls.affirmationQuestions)
                ]);
                setCoAppList(coAppResponse.data);
                setApplicant(applicationResponse.data);
                setQuestionsList(questionResponse.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        })();
        // eslint-disable-next-line
    },[]);

    // The CSS for the Utah Design System is scoped to the
    // 'utah-design-system' class and is optional if you
    // prefer cooking your own.
    return (
        loading
        ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Affirmations
                                </h1>
                                <div className={"application__group31"}>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Please review each statement and check after confirming</h4>
                                        </div>
                                        <div className={"application__vertical"}>
                                            <AffirmationQuestionList
                                                signed={!!applicant.signature}
                                                onAllChecked={allChecked}
                                                questionList={questionsList}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Applicant Signature</h4>
                                        </div>
                                        <p>Type your name to sign. </p>
                                        <div className={"application__vertical"}>
                                            <TextInput
                                                id={"signature"}
                                                name={"signature"}
                                                label={applicant?.firstName+"'s Signature"}
                                                isDisabled={!checked}
                                                onChange={(e) => handleSigChange(e.target.name, e.target.value)}
                                                value={applicant?.signature}
                                                isRequired
                                                autoComplete="off"
                                            />
                                            <Signature value={applicant?.signature} />
                                        </div>
                                        {coAppList && coAppList.length > 0 ? (
                                            <div>
                                                <div className={"application-title"}>
                                                    <h4>Co-Applicant Signature(s)</h4>
                                                </div>
                                                <p>If the co-applicant is a minor or incapacitated adult, include the signature of the parent or legal guardian.</p>
                                                <div className={"application__vertical"}>
                                                    {coAppList.map((coapplicant,index) =>
                                                    <div key={coapplicant.coapplicantId}>
                                                        <TextInput
                                                            id={`Coapplicant-${coapplicant.coapplicantId}`}
                                                            value={coAppList[index]?.signature}
                                                            label={coAppList[index]?.firstName+"'s Signature"}
                                                            isDisabled={!checked}
                                                            onChange={(e) => handleCoAppSigChange(index, e.target.value)}
                                                            isRequired
                                                            autoComplete="off"
                                                        />
                                                        <Signature value={coAppList[index]?.signature} />
                                                    </div>
                                                        )}
                                                </div>
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.options}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                            onSubmit={handleSubmit}/>
                    </main>
                </div>
            )
    );

}

export default AffirmationPg;

import '../App.css';

import '@utahdts/utah-design-system-header/css';
import {LogoDisplay} from "../elements/LogoDisplay";
import React from "react";

const Layout = () => {
    return(
        <nav>
            <ul>
                <LogoDisplay/>
            </ul>
        </nav>
    )
};

export default Layout;

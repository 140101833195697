import {Button} from "@utahdts/utah-design-system";
import {useNavigate} from "react-router-dom";


function AppNavigation({onNext, onBack, onSubmit, nextLabel, backLabel, nextIcon, backIcon}) {

    const navigate = useNavigate();

    const handleNextClick = () => {
        if (onSubmit) { onSubmit(); }
        if (onNext) { navigate(onNext); }
    };

    const handleBack = () => { if (onBack) { navigate(onBack); } }

    return (
        <div>
            <br/>
            <hr/>
            <div className="app-navigation">
                <div className={"app-navigation__nav-left"}>
                {onBack &&
                    <Button
                        appearance={"solid"}
                        className="button--primary-color app-navigation__nav-left-lbutton"
                        onClick={handleBack}
                        iconLeft={backIcon}
                    >
                        {backLabel}
                    </Button>
                }
                </div>
                <div className={"app-navigation__nav-right"}>
                    <Button
                        appearance={"solid"}
                        className="button--secondary-color app-navigation__nav-right-rbutton"
                        onClick={handleNextClick}
                        iconRight={nextIcon}
                    >
                        {nextLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AppNavigation;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    TableWrapper,
    Table,
    TableHead,
    TableHeadRow,
    TableHeadCell,
    TableBody,
    TableBodyData,
    TableBodyDataRowTemplate,
    TableBodyDataCellTemplate, Button,
} from '@utahdts/utah-design-system';
import React from "react";
import routingUrls from "../enum/routingUrls.js";
import {useNavigate} from "react-router-dom";

const propTypes = {
    taxList: PropTypes.arrayOf(PropTypes.shape({
        individualId: PropTypes.number.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        last4OfSSN: PropTypes.string,
    })),

};
const defaultProps = {};

function Tax({
                 taxList,
             }) {
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate(routingUrls.options);
    };

    return (
        taxList.length
            ? (
                <>
                    <div className="application-title">
                        <h4>Tax Information</h4>
                    </div>
                    <div className="application__vertical">
                        <TableWrapper id="tax-table">
                            <Table className="table--lines-x table--alt table--full-width mb-spacing">
                                <TableHead>
                                    <TableHeadRow>
                                        <TableHeadCell recordFieldPath="firstName">First Name</TableHeadCell>
                                        <TableHeadCell recordFieldPath="lastName">Last Name</TableHeadCell>
                                        <TableHeadCell recordFieldPath="last4OfSSN">Last 4 of SSN</TableHeadCell>
                                    </TableHeadRow>
                                </TableHead>
                                <TableBody>
                                    <TableBodyData records={taxList} recordIdField="individualId">
                                        <TableBodyDataRowTemplate>
                                            <TableBodyDataCellTemplate recordFieldPath="firstName" />
                                            <TableBodyDataCellTemplate recordFieldPath="lastName" />
                                            <TableBodyDataCellTemplate recordFieldPath="last4OfSSN" />
                                        </TableBodyDataRowTemplate>
                                    </TableBodyData>
                                </TableBody>
                            </Table>
                        </TableWrapper>
                        <div className="application__vertical-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
            : (
                <>
                    <div className="application-title">
                        <h4>Tax Information</h4>
                    </div>
                    <div className="application__group">
                        <p>No Records Found</p>
                        <div className="application__group-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
    );
}

Tax.propTypes = propTypes;
Tax.defaultProps = defaultProps;

export default Tax;

import '../App.css';

import '@utahdts/utah-design-system-header/css';
import routingUrls from "../enum/routingUrls.js";
import React, {useEffect, useState} from "react";
import {Spinner} from "@utahdts/utah-design-system";
import AppNavigation from "../elements/AppNavigation.jsx";

function CompletionPg() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        loading
        ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div className={"application"}>
                            <h1 className={"mt-spacing"}>
                                Application Complete
                            </h1>
                            <div className={"application__vertical-max60"}>
                                <div className={"application__vertical"}>
                                    <div className={"application-title"}>
                                        <h4>Thank you for completing the Safe At Home application!</h4>
                                    </div>
                                    <p>The program assistant will receive confirmation of this application submission.  Safe at Home will review the application within seven days and both the program assistant and applicant will be notified when the applicant is enrolled.  Once an applicant becomes a program participant, Safe at Home will reach out to them and mail them a Welcome Packet that will contain information on how to use the program and what to do next.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            onNext={routingUrls.landing}
                            nextLabel={"Close"}
                        />
                    </main>
                </div>
            )
    );
}

export default CompletionPg;

import {useApi} from "../hooks/useApi.js";
import {
    Button,
    ComboBox,
    Form,
    Select,
    Spinner,
    TextInput,
    useBanner
} from "@utahdts/utah-design-system";
import React, {useContext, useEffect, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useImmer} from "use-immer";
import apiUrls from "../enum/apiUrls.js";
import {buildBanner} from "../services/bannerService.jsx";
import {courtOrderSchema} from "../services/validationService.js";
import routingUrls from "../enum/routingUrls.js";
import SelectOptions from "../elements/SelectOptions.jsx";
import AppNavigation from "../elements/AppNavigation.jsx";
import CourtOrderCard from "../elements/CourtOrderCard";
import ComboBoxOptions from "../elements/ComboBoxOptions.jsx";
import {PatternFormat} from "react-number-format";
import isEqual from "react-fast-compare";


function CourtOrderPg () {

    const apiAddr  = useApi();
    const { addBanner } = useBanner();
    const { appState: { appId, returnReview }} = useContext(AppIdContext);
    const [loading, setLoading] = useState(true);
    const [caseTypeList, setCaseTypeList] = useState([]);
    const [judgeOrderList, setJudgeOrderList] = useState([]);
    const [courtOrderList, setCourtOrderList] = useImmer([]);
    const [countyList, setCountyList] = useImmer([]);
    const [courtOrder, setCourtOrder] = useImmer({
        courtOrderId: "",
        applicationId: appId,
        court: "",
        judge: "",
        county: 0,
        caseNumber: "",
        dateFiled: "",
        namesOfParties: "",
        caseType: "0",
        judgeOrder: "0"
    });

    const initialState = {
        courtOrderId: "",
        applicationId: appId,
        court: "",
        judge: "",
        county: 0,
        caseNumber: "",
        dateFiled: "",
        namesOfParties: "",
        caseType: "0",
        judgeOrder: "0"
    }

    useEffect(() => {
        (async () => {
            const [
                courtOrderListResponse,
                caseTypeResponse,
                judgeOrderResponse,
                countyResponse,
            ] = await Promise.all( [
                apiAddr.get(apiUrls.courtOrderList+appId),
                apiAddr.get(apiUrls.caseTypeList),
                apiAddr.get(apiUrls.confirmationList),
                apiAddr.get(apiUrls.countyList),
            ]);
            setCourtOrderList(courtOrderListResponse.data);
            setCaseTypeList(caseTypeResponse.data);
            setJudgeOrderList(judgeOrderResponse.data);
            setCountyList(countyResponse.data);
            setLoading(false);

        })();
        // eslint-disable-next-line
    },[]);

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    const handleEdit = (courtOrderId) => {
        apiAddr.get('/court-order/single/'+courtOrderId)
            .then(courtOrderResponse => {
                setCourtOrder(courtOrderResponse.data);
            });
    }

    const handleDelete = () => {
        apiAddr.get('/court-order/list/'+appId)
            .then(courtOrderListResponse => {
                setCourtOrderList(courtOrderListResponse.data);
            })
    }

    const handleAdd = async () => {
        try {
            await courtOrderSchema.validate(courtOrder);
            await apiAddr.post(apiUrls.courtOrderNew, courtOrder)
                .then(() => {
                    apiAddr.get('/court-order/list/'+appId)
                        .then(courtOrderListResponse => {
                            setCourtOrder(initialState);
                            setCourtOrderList(courtOrderListResponse.data);
                        });
                });
        } catch (errors) {
            errorBanner(errors.errors || errors.message);
        }
    };

    const disableButton = isEqual(initialState,courtOrder);

    const handleClear = () => { setCourtOrder(initialState);}

    const handleChange = (name, value) => {
        setCourtOrder({...courtOrder, [name] : value});
    }

    function countList(list) {
        return list ? list.length : 0;
    }

    return(
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Court Order Information
                                </h1>
                                <br/>
                                <p className={"application__vertical-max50"}>If any person listed on this application is subject to an existing court order or court action within Utah related to divorce, child support, child custody, or parent time, please list information about the case below.  Please provide as much of the information below as you are able to.  Safe at Home will work with the courts to redact applicant’s information from court documents.  Please do not assume this has happened without confirmation from Safe at Home.  You can also contact the court directly to change your address information in any existing court cases.</p>
                                <div className={"application__group12"}>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Court Order</h4>
                                        </div>
                                        <Form id={"courtOrder"}
                                              state={courtOrder}
                                              setState={setCourtOrder}
                                              autoComplete={"hidden"}
                                        >
                                            <div className={"application__vertical"}>
                                                <Select
                                                    id="caseType"
                                                    label="Case Type"
                                                    isDisabled={false}
                                                    value={courtOrder?.caseType}
                                                    autoComplete="off"
                                                >
                                                    <SelectOptions optionName="courtOrder.caseTypeOptions" value={caseTypeList} />
                                                </Select>
                                                <TextInput
                                                    id="court"
                                                    label="Court"
                                                    isDisabled={false}
                                                    value={courtOrder?.court}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id="judge"
                                                    label="Judge"
                                                    isDisabled={false}
                                                    value={courtOrder?.judge}
                                                    autoComplete="off"
                                                />
                                                <ComboBox
                                                    id={"county"}
                                                    label={"County"}
                                                    isDisabled={false}
                                                    value={courtOrder?.county}
                                                    autoComplete="off"
                                                >
                                                    <ComboBoxOptions optionName={"counties"} value={countyList}/>
                                                </ComboBox>
                                                <TextInput
                                                    id="caseNumber"
                                                    label="Case Number"
                                                    isDisabled={false}
                                                    value={courtOrder?.caseNumber}
                                                    autoComplete="off"
                                                />
                                                <div className={"input-wrapper input-wrapper--text-input"}>
                                                    <label>Date Filed</label>
                                                    <PatternFormat format={"##/##/####"}
                                                                   placeholder={"mm/dd/yyyy"}
                                                                   className={"PatternFormat"}
                                                                   name={"dateFiled"}
                                                                   onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                                   value={courtOrder?.dateFiled}
                                                                   autoComplete="off"
                                                    />
                                                </div>
                                                <TextInput
                                                    id="namesOfParties"
                                                    label="Names Of Parties"
                                                    isDisabled={false}
                                                    value={courtOrder?.nameOfParties}
                                                    autoComplete="off"
                                                />
                                                <Select
                                                    id="judgeOrder"
                                                    label="Judge Order"
                                                    isDisabled={false}
                                                    value={courtOrder?.judgeOrder}
                                                    autoComplete="off"
                                                >
                                                    <SelectOptions optionName="courtOrder.judgeOrderOptions" value={judgeOrderList} />
                                                </Select>
                                                <div className={"application__coapp-buttons"}>
                                                    <div className="application__coapp-buttons-left">
                                                        <Button
                                                            appearance="outlined"
                                                            color="primary"
                                                            id="button-applicant"
                                                            onClick={handleClear}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                    <div className="application__coapp-buttons-right">
                                                        <Button
                                                            iconRight={<span className={`utds-icon-before-plus`} aria-hidden="true" />}
                                                            appearance="solid"
                                                            color="secondary"
                                                            id="button-applicant"
                                                            onClick={handleAdd}
                                                            isDisabled={disableButton}
                                                        >
                                                            {courtOrder.courtOrderId ? "Update" : "Add"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div>
                                        <div className={"card-section"}>
                                            <div className={"card-section__card card-section__card-wide"}>
                                                <div className={"card-section__card-title"}>
                                                    <span className={`utds-icon-before-mail`} aria-hidden="true" />
                                                    <h3>Court Orders</h3>
                                                </div>
                                                <div className={"card-section__card-count"}>
                                                    {countList(courtOrderList)}
                                                </div>
                                                {
                                                    courtOrderList?.length
                                                        ?(
                                                            <CourtOrderCard
                                                                tableName={"CourtOrder"}
                                                                tableList={courtOrderList}
                                                                onEdit={handleEdit}
                                                                onDelete={handleDelete}
                                                            />
                                                        )
                                                        : <div className={"card-section__card-footer"}>No Records Match Criteria</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            onNext={returnReview === 1 ? routingUrls.review : routingUrls.file}
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.coapp}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                        />
                    </main>
                </div>
            )
    )

}

export default CourtOrderPg;

/* eslint-disable react/jsx-one-expression-per-line */
import React, {useContext, useEffect, useState} from "react";
import {useApi} from "../hooks/useApi.js";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {Spinner} from "@utahdts/utah-design-system";
import apiUrls from "../enum/apiUrls.js";
import {useImmer} from "use-immer";
import Applicant from "../elements/Applicant.jsx";
import ApplicantAddress from "../elements/ApplicantAddress.jsx";
import Coapplicant from "../elements/Coapplicant.jsx";
import CourtOrder from "../elements/CourtOrder.jsx";
import AppNavigation from "../elements/AppNavigation.jsx";
import routingUrls from "../enum/routingUrls.js";
import Tax from "../elements/Tax.jsx";
import Dmv from "../elements/Dmv.jsx";
import OptionInfo from "../elements/OptionInfo.jsx";

const propTypes = {};
const defaultProps = {};

function ReviewPage() {

    const apiAddr = useApi();
    const { appState: { appId }, setAppState } = useContext(AppIdContext);
    const [application, setApplication] = useImmer({});
    const [taxList, setTaxList] = useState([]);
    const [dmvList, setDmvList] = useState([]);
    const [optionInfo, setOptionInfo] = useState();
    const [genderList, setGenderList] = useState([]);
    const [pronounList, setPronounList] = useState([]);
    const [addressTypeList, setAddressTypeList] = useState([]);
    const [caseTypeList, setCaseTypeList] = useState([]);
    const [confirmationList, setConfirmationList] = useState([]);
    const [countyList, setCountyList] = useState([]);
    const [enrollmentReasonList, setEnrollmentReasonList] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleComplete = () => {
        //mark as complete, pending and update date to be day that it is completed, remove id from session
        apiAddr.patch(apiUrls.complete + appId).then();
        setAppState((appState) => {appState.returnReview = 0;});
        sessionStorage.removeItem('appId');
    }


    useEffect(() => {
        (async () => {
            try {
                const [
                    applicationResponse,
                    taxResponse,
                    dmvResponse,
                    optionResponse,
                    genderResponse,
                    pronounResponse,
                    addressTypeResponse,
                    caseTypeResponse,
                    judgeOrderResponse,
                    enrollmentReasonResponse,
                    countyResponse,
                ] = await Promise.all([
                    apiAddr.get(apiUrls.applicationGet+ appId),
                    apiAddr.get(apiUrls.taxList + appId),
                    apiAddr.get(apiUrls.dmvList + appId),
                    apiAddr.get(apiUrls.optionInfoGet + appId),
                    apiAddr.get(apiUrls.genderList),
                    apiAddr.get(apiUrls.pronounList),
                    apiAddr.get(apiUrls.addressTypeList),
                    apiAddr.get(apiUrls.caseTypeList),
                    apiAddr.get(apiUrls.confirmationList),
                    apiAddr.get(apiUrls.enrollmentReasonList),
                    apiAddr.get(apiUrls.countyList),
                ]);
                setApplication(applicationResponse.data);
                setTaxList(taxResponse.data);
                setDmvList(dmvResponse.data);
                setOptionInfo(optionResponse.data);
                setGenderList(genderResponse.data);
                setPronounList(pronounResponse.data);
                setAddressTypeList(addressTypeResponse.data);
                setCaseTypeList(caseTypeResponse.data);
                setConfirmationList(judgeOrderResponse.data);
                setEnrollmentReasonList(enrollmentReasonResponse.data);
                setCountyList(countyResponse.data);
                setLoading(false);
                setAppState((appState) => {appState.returnReview = 1;});
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return(
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Review
                                </h1>
                                <br/>
                                <p className={"application__vertical-max50"}>Please review all of the information to make sure it looks right.</p>
                                <div className={"application__review"}>
                                    <Applicant
                                        genderList={genderList}
                                        pronounList={pronounList}
                                        enrollmentReasonList={enrollmentReasonList}
                                        state={application}
                                        setState={setApplication}
                                    />

                                    <ApplicantAddress
                                        title="Applicant Address"
                                        path="applicantAddress"
                                        addressTypeList={addressTypeList}
                                        address={application?.applicantAddress}
                                        countyList={countyList}
                                        state={application}
                                        setState={setApplication}
                                    />

                                    <ApplicantAddress
                                        title="Applicant Mailing Address"
                                        path="applicantMailingAddress"
                                        addressTypeList={addressTypeList}
                                        address={application?.applicantMailingAddress}
                                        countyList={countyList}
                                        state={application}
                                        setState={setApplication}
                                    />

                                    <Coapplicant
                                        coapplicantList={application?.coapplicantList}
                                        genderList={genderList}
                                        pronounList={pronounList}
                                        state={application}
                                        setState={setApplication}
                                    />

                                    <CourtOrder
                                        courtOrderList={application?.courtOrderList}
                                        confirmationList={confirmationList}
                                        caseTypeList={caseTypeList}
                                        countyList={countyList}
                                        state={application}
                                        setState={setApplication}
                                    />

                                    <OptionInfo
                                        confirmationList={confirmationList}
                                        options={optionInfo}
                                    />

                                    <Tax taxList={taxList} />
                                    <Dmv dmvList={dmvList} />
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            onNext={routingUrls.comp}
                            nextLabel={"Submit"}
                            nextIcon={<span className={`utds-icon-before-check`} aria-hidden="true" />}
                            onBack={routingUrls.rec}
                            backLabel={"Back"}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            onSubmit={handleComplete}
                        />
                    </main>
                </div>
            )
    )

}

ReviewPage.propTypes = propTypes;
ReviewPage.defaultProps = defaultProps;

export default ReviewPage;

/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import { SelectOption } from '@utahdts/utah-design-system';

const propTypes = {
  optionName: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    isActive: PropTypes.number,
  })),

};
const defaultProps = {};

function SelectOptions({ optionName, value }) {
  return (
    value
      ? (
        value.map((item) => (
          <SelectOption
            key={`${optionName}-${item.id}`}
            value={item.id}
            label={item.value}
          />
        ))
      )
      : ''
  );
}

SelectOptions.propTypes = propTypes;
SelectOptions.defaultProps = defaultProps;

export default SelectOptions;

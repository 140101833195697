import PropTypes from 'prop-types';
import {
    Button, Form,  Select, TextInput
} from '@utahdts/utah-design-system';
import React, { useEffect, useState } from 'react';
import SelectOptions from './SelectOptions.jsx';
import {useNavigate} from "react-router-dom";
import routingUrls from "../enum/routingUrls.js";

const propTypes = {
    options: PropTypes.shape({
        additionalOptionsId: PropTypes.number,
        applicationId: PropTypes.string,
        abuserWorkForGovt: PropTypes.string,
        abuserAgency: PropTypes.string,
        selfWorkForGovt: PropTypes.string,
        transparentUtah: PropTypes.string,
        selfAgency: PropTypes.string,
    }),
    confirmationList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        isActive: PropTypes.number,
    })),
};
const defaultProps = {};

function OptionInfo({
                        options,
                        confirmationList,
                    }) {
    const navigate = useNavigate();
    const [optionInfo, setOptionInfo] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;

        setOptionInfo({ ...optionInfo, [name]: value });
    };
    const handleSubmit = () => {
        navigate(routingUrls.options);
    };

    useEffect(() => {
        setOptionInfo(options);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="application-title">
                <h4>Optional Employment Information</h4>
            </div>
            <Form>
                <div className="application__review-group">
                    <Select
                        id="option-info-abuser-work-for-govt"
                        label="Feared Individual Works For Government"
                        name="abuserWorkForGovt"
                        value={optionInfo?.abuserWorkForGovt}
                        onChange={(e) => handleChange(e)}
                        isDisabled
                    >
                        <SelectOptions optionName="confirmation-list-abuser" value={confirmationList} />
                    </Select>
                    <TextInput
                        id="abuser-agency"
                        label="Feared Individual's Agency"
                        name="abuserAgency"
                        value={optionInfo?.abuserAgency}
                        onChange={(e) => handleChange(e)}
                        isDisabled
                    />
                    <div />
                    <Select
                        id="option-info-self-work-for-govt"
                        label="Applicant Works For Government"
                        name="selfWorkForGovt"
                        value={optionInfo?.selfWorkForGovt}
                        onChange={(e) => handleChange(e)}
                        isDisabled
                    >
                        <SelectOptions optionName="confirmation-list-self" value={confirmationList} />
                    </Select>
                    <Select
                        id="option-info-transparent-utah"
                        label="Hide Info On Transparent Utah"
                        name="transparentUtah"
                        value={optionInfo?.transparentUtah}
                        onChange={(e) => handleChange(e)}
                        isDisabled
                    >
                        <SelectOptions optionName="confirmation-list-transparent" value={confirmationList} />
                    </Select>
                    <TextInput
                        id="self-agency"
                        label="Applicant's Agency"
                        name="selfAgency"
                        value={optionInfo?.selfAgency}
                        onChange={(e) => handleChange(e)}
                        isDisabled
                    />
                    <div className="application__group-bottom-right">
                        <Button
                            iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                            appearance="solid"
                            color="secondary"
                            id="button-applicant"
                            onClick={handleSubmit}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

OptionInfo.propTypes = propTypes;
OptionInfo.defaultProps = defaultProps;

export default OptionInfo;

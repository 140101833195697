import {Button, FileInput, RadioButton, RadioButtonGroup, useBanner} from "@utahdts/utah-design-system";
import routingUrls from "../enum/routingUrls.js";
import {useApi} from "../hooks/useApi.js";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import apiUrls from "../enum/apiUrls.js";
import FileCard from "../elements/FileCard.jsx";
import AppNavigation from "../elements/AppNavigation.jsx";

import {useNavigate} from "react-router-dom";
import {buildBanner} from "../services/bannerService.jsx";
import {fileValidate} from "../services/fileValidationService.js";
import {fileSchema} from "../services/validationService.js";

function FileSubmission(){


    const [files, setFiles] = useState([]);
    const [radioValue, setRadioValue] = useState("0");
    const [dbFiles, setDbFiles]= useState([]);
    const {accessToken} = useOidcAccessToken();
    const fileInputRef = useRef(null);
    const apiAddr = useApi();
    const navigate = useNavigate();
    const { addBanner } = useBanner();
    const { appState: { appId } } = useContext(AppIdContext);
    const initialState = [];


    const [addButtonDisabled, setAddButtonDisabled] = useState(true);

    useEffect(() => {
        apiAddr.get(apiUrls.fileList+appId)
            .then(fileResponse => {
                setDbFiles(fileResponse.data);
            })
        // eslint-disable-next-line
    },[]);

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));



    const handleChange = (fileList) => {

        setAddButtonDisabled(true);
        setRadioValue(0);
        setFiles(fileList);

    }

        const handleAdd = async () => {

            if(fileValidate() === true) {
            for (const file of files) {


                const document = new FormData();

                document.set("docAttributes", file.name);
                document.set("applicationId", appId);
                document.set("document", file);
                document.set("offenseDocumentTypeId", radioValue);


                await apiAddr.post(apiUrls.fileSubmit, document, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${accessToken}`
                    }
                }).then(() => {
                    apiAddr.get(apiUrls.fileList + appId)
                        .then(fileResponse => {
                            setDbFiles(fileResponse.data);
                            handleChange(initialState);


                        })
                    setAddButtonDisabled(true);
                }).catch((error) => {
                    console.log(error.response.data);
                });
            }
            }else{

                handleChange(initialState);
                return false;

            }
        }

    const handleDelete = () => {
        apiAddr.get(apiUrls.fileList+appId)
            .then(response => {
                setDbFiles(response.data);

            });
    }

    const handleRadioChange = (value) => {
        setRadioValue(value);
       setAddButtonDisabled(false);
    }

    const handleSubmit = async () => {
        try {
            await fileSchema.validate(dbFiles);
            navigate(routingUrls.options);
        } catch (error) {
            errorBanner(error.message);
        }
    }

    return (
        <div className={"full-width"}>
            <main className={"content-width"}>
                <div>
                    <div className={"application"}>
                        <h1 className={"mt-spacing"}>
                            Submit Documentation
                        </h1>

                       <p  className="application__vertical-max50">
                            Some type of documentation of victimization or threat is required to support the application. This could be something like a police report or order of protection, but it could also be something like a letter from a victim advocate. You can see further examples of types of documentation in the three options below.  If you have questions about what documentation would be accepted, contact Safe at Home.
                        </p>
                        <br />

                        <div className={"application__vertical-max60"}>
                            <div className={"application__vertical"}>
                                <div className={"application-title"}>
                                    <h4>Files</h4>
                                </div>

                                <FileInput
                                    acceptedFileTypes="image/png, application/pdf, image/jpeg, application/msword,
                                    image/heic, image/gif, image/svg+xml, image/webp,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    id="files"
                                    label="Choose a file, then select the type of documentation you are submitting and click the add button. "
                                    hint="Accepted file types png, pdf, gif, jpeg, doc, docx, svg, webp and heic."
                                    onChange={(file) => handleChange(file)}
                                    innerRef={fileInputRef}
                                    autoComplete="off"
                                />

                                <RadioButtonGroup
                                    id="fileRadio"
                                    label="Select a file classification"
                                    onChange={(e)=> handleRadioChange(e)}
                                    value = {radioValue}
                                >
                                    <RadioButton
                                        id="1"
                                        value="1"
                                        label={"Documentation from law enforcement, court, or other government " +
                                            "agency (example: police report, order of protection)"}
                                    />
                                    <RadioButton
                                        value="2"
                                        id="2"
                                        label={"Documentation from a domestic violence, sexual assault, or trafficking program " +
                                            "(example: letter from a shelter employee or victim advocate)"}
                                    />
                                    <RadioButton
                                        value="3"
                                        id="3"
                                        label={"Documentation from a religious, medical, or other professional from whom the applicant " +
                                            "has sought assistance in dealing with the domestic violence, sexual assault, stalking, or " +
                                            "trafficking (example: letter from a therapist or clergy member that has counseled the victim)"}
                                    />
                                </RadioButtonGroup>


                                <div className="application__button-center">

                                    <Button
                                        iconRight={<span className={`utds-icon-before-upload`} aria-hidden="true" />}
                                        appearance="solid"
                                        color="secondary"
                                        id="button-file-add"
                                        onClick={handleAdd}
                                        isDisabled={addButtonDisabled}
                                    >
                                        Add
                                    </Button>

                                </div>
                            </div>
                        </div>
                        {
                            dbFiles?.length
                                ? (
                                    <FileCard tableName={"Files"}
                                              tableList={dbFiles}
                                              onDelete={handleDelete}/>
                                ) : null
                        }
                    </div>
                </div>
                <AppNavigation
                    nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                    nextLabel={"Save & Continue"}
                    onBack={routingUrls.court}
                    backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                    backLabel={"Back"}
                    onSubmit={handleSubmit}
                />
            </main>
        </div>
    )
}
export default FileSubmission;

/* eslint-disable react/jsx-one-expression-per-line */
import {useNavigate} from "react-router-dom"
import React, {useContext, useEffect, useState} from 'react';
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useApi} from "../hooks/useApi.js";
import {Spinner} from "@utahdts/utah-design-system";
import IncompleteCard from "../elements/IncompleteCard.jsx";
import apiUrls from "../enum/apiUrls";
import routingUrls from "../enum/routingUrls.js";

const propTypes = {};
const defaultProps = {};

function LandingPg()  {

    const apiAddr = useApi();
    const {setAppState} = useContext(AppIdContext);
    const navigate = useNavigate();
    const [pendingApplications, setPendingApplications] = useState([]);
    const [loading, setLoading] = useState(true);

    function navPg1 (){
        setAppState((appState) => {
            appState.appId = undefined;
        });
        navigate(routingUrls.info);
    }

    useEffect(() => {
        (async () => {
            try {
                const [
                    pendingApplicationResponse
                ] = await Promise.all([
                    apiAddr.get(apiUrls.applicationIncompleteList)
                ]);
                setPendingApplications(pendingApplicationResponse.data);
                setLoading(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const handleDelete = () => {
        apiAddr.get(apiUrls.applicationIncompleteList)
            .then((response) => {
                setPendingApplications(response.data);
            })
    }

    function countList(list) {
        return list ? list.length : 0;
    }

    // The CSS for the Utah Design System is scoped to the
    // 'utah-design-system' class and is optional if you
    // prefer cooking your own.
    return (
        loading
        ?<Spinner/>
            :(
                    <main className={"content-width"}>
                        <div>
                            <center>
                            <h1>Welcome to Safe at Home</h1>
                            <br/>
                                <p className="application__vertical-max50">
                                    This application is meant to be completed with the program assistant and applicant together. This takes approximately 10 to 15 minutes to complete. All required fields are marked with an asterisk (*) and you will not be able to move forward in the application until required fields are complete.<br/><br/>If you begin an application and are unable to complete it in one sitting, it will be saved for 30 days and listed below. You will be able to edit and complete the incomplete application in that time. After 30 days an incomplete application will be deleted for security purposes.
                                </p>

                                <button
                                    color="none"
                                    className={"button--secondary-color" }
                                    onClick={navPg1}>START APPLICATION</button>
                            <br/>
                            </center>
                            <div className={"card-section"}>
                                <div className={"card-section__card card-section__card-wide"}>
                                    <div className={"card-section__card-title"}>
                                        <span className={`utds-icon-before-restart`} aria-hidden="true" />
                                        <h3>Incomplete Applications</h3>
                                    </div>
                                    <div className={"card-section__card-count"}>
                                        {countList(pendingApplications)}
                                    </div>
                                    {
                                        pendingApplications?.length
                                        ?(
                                            <IncompleteCard
                                                tableName={"pendingApplications"}
                                                tableList={pendingApplications}
                                                onDelete={handleDelete}
                                            />
                                            )
                                            : <div className={"card-section__card-footer"}>No Records Match Criteria</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </main>

            )
    );
}

LandingPg.propTypes = propTypes;
LandingPg.defaultProps = defaultProps;

export default LandingPg;



/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    Select,
    TextInput,
    Form, Button, ComboBox,
    // useBanner
} from '@utahdts/utah-design-system';
import SelectOptions from './SelectOptions.jsx';
import {useNavigate} from "react-router-dom";
import routingUrls from "../enum/routingUrls.js";
import ComboBoxOptions from "./ComboBoxOptions.jsx";
import React from "react";

const propTypes = {
    courtOrderList: PropTypes.arrayOf(PropTypes.shape({
        court: PropTypes.string,
        judge: PropTypes.string,
        county: PropTypes.string,
        caseNumber: PropTypes.string,
        dateFiled: PropTypes.string,
        nameOfParties: PropTypes.string,
        caseType: PropTypes.string,
        judgeOrder: PropTypes.string,
        isActive: PropTypes.number,
    })),
    confirmationList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        isActive: PropTypes.number,
    })),
    caseTypeList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        isActive: PropTypes.number,
    })),
    state: PropTypes.object,
    setState: PropTypes.func,

};
const defaultProps = {};

function CourtOrder(
    {
        courtOrderList,
        confirmationList,
        caseTypeList,
        countyList,
        state,
        setState,
    }
) {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(routingUrls.court);
    };

    return (
        courtOrderList.length
            ? (
                courtOrderList.map((courtOrder, index) => (
                    <div key={`courtOrder-${courtOrder.courtOrderId}`} className="application__courtOrder">
                        <div className="application-title">
                            <h4>Court Order</h4>
                        </div>
                        <Form id={`courtOrder-form-${courtOrder.courtOrderId}`} state={state} setState={setState}>
                            <div className="application__review-group">
                                <Select
                                    id={`courtOrderList.${index}.caseType`}
                                    label="Case Type"
                                    isDisabled
                                    value={courtOrder?.caseType}
                                >
                                    <SelectOptions optionName={`courtOrderList.${index}.caseType`} value={caseTypeList} />
                                </Select>
                                <TextInput
                                    id={`courtOrderList.${index}.court`}
                                    label="Court"
                                    isDisabled
                                    value={courtOrder?.court}
                                />
                                <TextInput
                                    id={`courtOrderList.${index}.judge`}
                                    label="Judge"
                                    isDisabled
                                    value={courtOrder?.judge}
                                />
                                <ComboBox
                                    id={`courtOrderList.${index}.county`}
                                    name="county"
                                    label="County"
                                    placeholder="Search or Select..."
                                    isDisabled
                                    value={courtOrder?.county}
                                >
                                    <ComboBoxOptions optionName={`courtOrderList.${index}.county`} value={countyList} />
                                </ComboBox>
                                <TextInput
                                    id={`courtOrderList.${index}.caseNumber`}
                                    label="Case Number"
                                    isDisabled
                                    value={courtOrder?.caseNumber}
                                />
                                <TextInput
                                    id={`courtOrderList.${index}.dateFiled`}
                                    label="Date Filed"
                                    isDisabled
                                    value={courtOrder?.dateFiled}
                                />
                                <TextInput
                                    id={`courtOrderList.${index}.namesOfParties`}
                                    label="Names Of Parties"
                                    isDisabled
                                    value={courtOrder?.namesOfParties}
                                />
                                <Select
                                    id={`courtOrderList.${index}.court`}
                                    label="Judge Order"
                                    isDisabled
                                    value={courtOrder?.judgeOrder}
                                >
                                    <SelectOptions optionName={`courtOrderList.${index}.judgeOrder`} value={confirmationList} />
                                </Select>
                                <div />
                                <div className="application__group-bottom-right">
                                    <Button
                                        iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                        appearance="solid"
                                        color="secondary"
                                        id="button-applicant"
                                        onClick={handleSubmit}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                ))
            )
            : (
                <>
                    <div className="application-title">
                        <h4>Court Order</h4>
                    </div>
                    <div className="application__group">
                        <p>No Records Found</p>
                        <div className="application__group-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleSubmit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
    );
}

CourtOrder.propTypes = propTypes;
CourtOrder.defaultProps = defaultProps;

export default CourtOrder;

import {useApi} from "../hooks/useApi.js";
import React, {useContext, useEffect, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useImmer} from "use-immer";
import apiUrls from "../enum/apiUrls.js";
import routingUrls from "../enum/routingUrls.js";
import {Checkbox, Form, Spinner, TextInput, useBanner} from "@utahdts/utah-design-system";
import AppNavigation from "../elements/AppNavigation.jsx";
import {buildBanner} from "../services/bannerService.jsx";
import {useNavigate} from "react-router-dom";
import {assistantSchema, assistantSignatureSchema} from "../services/validationService.js";
import Signature from "../elements/Signature.jsx";
import {PatternFormat} from "react-number-format";


function AssistantRec() {

    const apiAddr  = useApi();
    const navigate = useNavigate();
    const { addBanner } = useBanner();
    const { appState: { appId, returnReview  } } = useContext(AppIdContext);
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useImmer({
        assistantSignature: "",
        assistantAffirmation: false
    });
    const [assistant, setAssistant] = useImmer({
        name: "",
        phone: "",
        agency: "",
        registrationNumber: ""
    });

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)))

    useEffect(() => {
        apiAddr.get(apiUrls.applicationGetById+appId)
            .then(result => {
                const applicationResponse = result.data;
                setApplication(applicationResponse);
                apiAddr.get(apiUrls.assistantById+applicationResponse.programAssistantId)
                    .then(assistantResponse => {
                        setAssistant(assistantResponse.data);
                    });
            });
        setLoading(false);
        // eslint-disable-next-line
    },[]);

    const handleChange = (name, value) => {
        setAssistant({...assistant, [name] : value});
    }

    const handleSubmit = async () => {
        try {
            await assistantSignatureSchema.validate(application);
            await assistantSchema.validate(assistant);
            await apiAddr.patch(apiUrls.signature, JSON.stringify(application))
                .then(function (response) {
                }).catch(function (error) {
                    console.log(error.response.data)
                });
            await apiAddr.patch(apiUrls.memberUpdate, assistant);
            navigate(routingUrls.review);
        } catch (error) {
            errorBanner(error.message);
        }

    }

    return (
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Program Assistant Recommendation
                                </h1>
                                <div className={"application__vertical-max50"}>
                                    <Form className={"recommendation"}
                                          state={application}
                                          setState={setApplication}
                                          autoComplete={"hidden"}
                                    >
                                        <div className={"application__vertical"}>
                                                <div>
                                                    <span className="application-label">{assistant?.name}</span>
                                                    <span className="application-value">#{assistant?.registrationNumber}</span>
                                                </div>
                                            <hr />
                                            <TextInput
                                                id={"agency"}
                                                name={"agency"}
                                                label={"Program Assistant Agency or Organization"}
                                                value={assistant?.agency}
                                                onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                isRequired
                                                autoComplete="off"
                                            />
                                            <div className={"input-wrapper input-wrapper--text-input"}>
                                                <label>Program Assistant Contact Number<span className={"required-star"}>*</span></label>
                                                <PatternFormat format={"(###) ###-####"}
                                                               placeholder={"(###) ###-####"}
                                                               className={"PatternFormat"}
                                                               name={"phone"}
                                                               onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                               value={assistant?.phone}
                                                               autoComplete="off"
                                                />
                                            </div>
                                            <Checkbox
                                                id={"assistantAffirmation"}
                                                className={"application-checkbox"}
                                                label={"I assisted in the preparation of this application and I \n" +
                                                    "believe that the applicant’s comprehensive safety plan should \n" +
                                                    "include the Safe at Home Program"}
                                                isDisabled={!!application.assistantSignature}
                                                value={application?.assistantAffirmation}
                                            />
                                            <>
                                                <TextInput
                                                    id={"assistantSignature"}
                                                    name={"assistantSignature"}
                                                    label={"Program Assistant Signature"}
                                                    isDisabled={!application.assistantAffirmation}
                                                    autoComplete="off"
                                                />
                                                <Signature value={application?.assistantSignature} />
                                            </>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            // onNext={routingUrls.review}
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.affirm}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                            onSubmit={handleSubmit}/>
                    </main>
                </div>
            )
    )
}
export default AssistantRec;

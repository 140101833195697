/* eslint-disable no-return-assign */
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import axios from "axios";

export const useApi = () => {
    const { accessToken } = useOidcAccessToken();

     return axios.create({
        baseURL: process.env.REACT_APP_BASE_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }
    });
};

import PropTypes from 'prop-types';
import { Spinner } from '@utahdts/utah-design-system';
import {AddrConfUserStatus} from "../hooks/useAddrConfUser.js";
import {useContext} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import Page403 from "../form_pages/Page403.jsx";

const propTypes = { children: PropTypes.node.isRequired };
const defaultProps = {};

export function RoleRoute({ children }) {
  const { appState: { addrConfUser, addrConfUserLoadingState } } = useContext(AppIdContext);
  switch (addrConfUserLoadingState) {
    case AddrConfUserStatus.Loaded:
        return (addrConfUser?.authorities?.some(authority => ['PROGRAM_ASSISTANT', 'ADMIN'].includes(authority))) ? children : <Page403 />;
    case AddrConfUserStatus.Loading:
      return <Spinner />;
    default:
      return <Page403 />;
  }
}

RoleRoute.propTypes = propTypes;
RoleRoute.defaultProps = defaultProps;

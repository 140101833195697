import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useApi} from "../hooks/useApi.js";
import {
    Button,
    Checkbox,
    Form,
    formElementSizesEnum,
    IconButton,
    Select,
    Spinner,
    TextInput,
    useBanner
} from "@utahdts/utah-design-system";
import {useImmer} from "use-immer";
import {buildBanner} from "../services/bannerService.jsx";
import apiUrls from "../enum/apiUrls.js";
import routingUrls from "../enum/routingUrls.js";
import SelectOptions from "../elements/SelectOptions.jsx";
import AppNavigation from "../elements/AppNavigation.jsx";
import {additionalOptionsSchema} from "../services/validationService.js";

function AdditionalOptionsPg() {

    const navigate = useNavigate();
    const { appState: { appId, returnReview } } = useContext(AppIdContext);
    const apiAddr  = useApi();
    const { addBanner } = useBanner();
    const [loading, setLoading] = useState(true);
    const [confirmationList, setConfirmationList] = useState([]);
    const [yesNoList, setYesNoList] = useState([]);
    const [coapplicantList, setCoapplicantList] = useState([]);
    const [applicantInfo, setApplicantInfo] = useState({});
    const [isModalShown, setIsModalShown] = useState(false);
    const [checkState, setCheckState] = useState([]);
    const [options, setOptions] = useImmer({
        additionalOptionId:"",
        applicationId: appId,
        abuserWorkForGovt: "",
        abuserAgency: "",
        selfWorkForGovt: "",
        transparentUtah: "",
        selfAgency: "",
        taxAddressUpdate: "",
        taxInfo: [],
        propertyRegisteredDmv: "",
        dmvAddressUpdate: "",
        vehicleList: [
            {
                vehicleId: "",
                applicationId: appId,
                vin: "",
                plateNumber: ""
            }
        ],
    })

    useEffect(() =>{
        apiAddr.get(apiUrls.confirmationList)
            .then(confirmationResponse => {
                setConfirmationList(confirmationResponse.data);
                setYesNoList(confirmationResponse.data.filter((item, index) => index !== 3));
            });
        apiAddr.get(apiUrls.additionalOption+appId)
            .then(optionsResponse => {
                if(optionsResponse.data !== '') {
                    setOptions(optionsResponse.data);
                }
                apiAddr.get(apiUrls.applicantGet + appId)
                    .then(applicantResponse => {
                        const applicant = {
                            applicationId: appId,
                            firstName: applicantResponse.data.firstName.toUpperCase(),
                            lastName: applicantResponse.data.lastName.toUpperCase(),
                        };
                        setApplicantInfo(applicant);
                        const vehicle = {
                            vehicleId: "",
                            applicationId: appId,
                            vin: "",
                            plateNumber: ""
                        };
                        if (optionsResponse.data.vehicleList.length === 0 ) {
                            setOptions((prevOptions) => ({
                                ...prevOptions,
                                vehicleList: [...prevOptions.vehicleList,vehicle],
                            }));
                        }
                    })
            });
        updateCoappList();
        setLoading(false);
        // eslint-disable-next-line
    },[]);

    const handleSubmit = async () => {
        try {
            await additionalOptionsSchema.validate(options);
            await apiAddr.post(apiUrls.additionalOptionNew, options);
            navigate(returnReview === 1 ? routingUrls.review : routingUrls.affirm);
        } catch (errors) {
            errorBanner(errors.errors || errors.message);
        }
    }

    const handleAddFields = () => {
        setOptions((draft) => {
            draft.vehicleList.push({ vehicleId: "", applicationId: appId, vin: "", plateNumber: "" });
        });
    };

    const handleRemoveFields = async(index) => {
        if(options.vehicleList[index].vehicleId !== "") {
            await apiAddr.delete(apiUrls.vehicleDelete + options.vehicleList[index].vehicleId);
        }
        setOptions((draft) => {
            draft.vehicleList.splice(index, 1);
        });
    };
    const handleSSNChange = (index, event) => {
        const { name, value } = event.target;
        const sanitizedValue = value.replace(/\D/g, '');
        setOptions(prevOptions => {
            return {
                ...prevOptions,
                taxInfo: prevOptions.taxInfo.map((individual, idx) => {
                    if (idx === index) {
                        return {
                            ...individual,
                            [name]: sanitizedValue
                        };
                    }
                    return individual;
                })
            };
        });
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const uppercaseValue = value.toUpperCase();
        setOptions(prevOptions => {
            return {
                ...prevOptions,
                vehicleList: prevOptions.vehicleList.map((vehicle, idx) => {
                    if (idx === index) {
                        return {
                            ...vehicle,
                            [name]: uppercaseValue
                        };
                    }
                    return vehicle;
                })
            };
        });
    };

    const handleCheckboxChange = (index) => {
        setCheckState((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleOkay = () => {
        // Add checked co-applicants to individual list and remove from coapplicantList
        const selectedCoapplicants = coapplicantList.filter((_, index) => checkState[index]).map(coapplicant => ({
            applicationId: appId,
            firstName: coapplicant.firstName.toUpperCase(),
            lastName: coapplicant.lastName.toUpperCase(),
        }));
        setOptions((prevOptions) => ({
            ...prevOptions,
            taxInfo: [...prevOptions.taxInfo, ...selectedCoapplicants],
        }));
        setCoapplicantList((prevList) =>
            prevList.filter((_, index) => !checkState[index])
        );
        setCheckState(new Array(coapplicantList.length).fill(false));
        setIsModalShown(false);
    };

    const updateCoappList = () => {
        apiAddr.get(apiUrls.coAppList + appId)
            .then(response => {
                if (response.data !== '') {
                    // Exclude co-applicants already in the taxInfo list
                    apiAddr.get(apiUrls.individualList + appId)
                        .then(individualResponse => {
                            if (individualResponse.data !== '') {
                                const filteredCoapplicants = response.data.filter(coapp =>
                                    !individualResponse.data.some(individual => individual.firstName.toUpperCase() === coapp.firstName.toUpperCase())
                                );
                                setCoapplicantList(filteredCoapplicants);
                            }
                        })
                }
            })
    }

    const handleDeleteSSN = async (indexToRemove) => {
        const individualId = options.taxInfo[indexToRemove]?.individualId;
        const removedEntry = options.taxInfo[indexToRemove];
        if (!individualId) {
            // If individualId is null, filter it out directly
            setOptions(prevOptions => ({
                ...prevOptions,
                taxInfo: prevOptions.taxInfo.filter((_, index) => index !== indexToRemove)
            }));
            // Re-add the removed entry to the coapplicantList
            if (indexToRemove !== 0) {
                setCoapplicantList(prevCoapplicants => [...prevCoapplicants, removedEntry]);
                setCheckState(new Array(coapplicantList.length).fill(false));
            }
            return; // Exit the function
        }

        try {
            // Make an API call to handle deletion before removing from taxInfo
            await apiAddr.delete(apiUrls.individualDelete+ individualId)
                .then(() => {
                    // Now, remove the entry from options.taxInfo
                    setOptions(prevOptions => ({
                        ...prevOptions,
                        taxInfo: prevOptions.taxInfo.filter((_, index) => index !== indexToRemove)
                    }));
                    updateCoappList();
                })

            setCheckState(new Array(coapplicantList.length).fill(false));
        } catch (errors) {
            errorBanner(errors.errors || errors.message);
        }
    };

    const addApplicantTax = () => {
        const newTaxObject = {
            individualId: "",
            firstName: applicantInfo?.firstName,
            lastName: applicantInfo?.lastName,
            applicationId: appId,
            last4OfSSN: "",
        }

        setOptions(draft => {
            draft.taxInfo.unshift(newTaxObject);
        });
    }

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    return(
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Additional Information
                                </h1>
                                <br/>
                                <p className={"application__vertical-max50"}>You are not required to submit the fields below to have tax and DMV records changed.  If the applicant does complete this information, Safe at Home will work directly with the tax commission and DMV to change these records to their substitute address without the applicant needing to take any further action.  Safe at Home will notify the applicant whether or not they were able to make the changes with the information given.  Please do not assume the changes have been made without confirmation.</p>
                                <div className={"application__vertical-max50"}>
                                    <Form id={"additional-options"}
                                          state={options}
                                          setState={setOptions}
                                          autoComplete={"hidden"}
                                    >
                                        <div className={"application__vertical"}>
                                            <Select
                                                id="abuserWorkForGovt"
                                                name={"abuserWorkForGovt"}
                                                label="Does the person you are in fear of work for a state or local government agency?"
                                                value={options?.abuserWorkForGovt}
                                                isRequired
                                                autoComplete="off"
                                            >
                                                <SelectOptions optionName="abuserWorkForGovtSelect" value={confirmationList} />
                                            </Select>
                                            {(options.abuserWorkForGovt === '1' || options.abuserWorkForGovt === 1) ?
                                                <TextInput
                                                    id="abuserAgency"
                                                    name={"abuserAgency"}
                                                    label="What agency does the person you are in fear of work for?"
                                                    value={options?.abuserAgency}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                : null}
                                            <hr/>
                                            <Select
                                                id="selfWorkForGovt"
                                                label="Are you (applicant) employed by a state or local government agency or school?"
                                                value={options?.selfWorkForGovt}
                                                isRequired
                                                autoComplete="off"
                                            >
                                                <SelectOptions optionName="selfWorkForGovtSelect" value={yesNoList} />
                                            </Select>
                                            {(options.selfWorkForGovt === '1' || options.selfWorkForGovt === 1) ?
                                                <>
                                                    <Select
                                                        id="transparentUtah"
                                                        label="Would you like to have your employment information removed from the Transparent Utah website?"
                                                        value={options?.transparentUtah}
                                                        isRequired
                                                        autoComplete="off"
                                                    >
                                                        <SelectOptions optionName="transparentUtahSelect" value={yesNoList} />
                                                    </Select>
                                                    <p className={"application-warning"}>
                                                        NOTE It is strongly recommended that you choose to have your employment
                                                        information hidden on the Transparent Utah website for your safety.
                                                    </p>
                                                </>
                                                : null}
                                            {(options.transparentUtah === '1' || options.transparentUtah === 1) ?
                                                (<TextInput
                                                    id="selfAgency"
                                                    name={"selfAgency"}
                                                    label="Which state or local government agency do you work for?"
                                                    value={options?.selfAgency}
                                                    isRequired
                                                    autoComplete="off"
                                                />)
                                                : null}
                                            <hr />
                                            <div className={"application-warning"}>NOTE: If you or your co-applicants have never filed taxes in Utah, you do not need to make this change</div>
                                            <Select
                                                id="taxAddressUpdate"
                                                label="Would you like your address to be changed to your new substitute address with the Utah State Tax Commission?"
                                                value={options?.taxAddressUpdate}
                                                isRequired
                                                autoComplete="off"
                                            >
                                                <SelectOptions optionName="taxAddressUpdateSelect" value={yesNoList} />
                                            </Select>
                                            {(options.taxAddressUpdate === '1' || options.taxAddressUpdate === 1) ?
                                                <>
                                                    {options?.taxInfo.map((individual, index) => (
                                                        <div key={index} className={"application__legal-names"}>
                                                            <div className="application__legal-names-content">
                                                                <TextInput
                                                                    id={`taxInfo.${index}.last4OfSSN`}
                                                                    name={`last4OfSSN`}
                                                                    label={`Last 4 digits of Social Security Number(SSN) for ${individual?.firstName.toUpperCase()}`}
                                                                    value={options.taxInfo[index]?.last4OfSSN}
                                                                    onChange={(e) => handleSSNChange(index, e)}
                                                                    maxLength={4}
                                                                    placeholder="xxxx"
                                                                    isRequired
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className={"application__legal-names-button"}>
                                                                <IconButton
                                                                    appearance="outlined"
                                                                    color="primary"
                                                                    icon={<span className={`utds-icon-before-trash`}
                                                                                aria-hidden="true"/>}
                                                                    id={`delete-${index}`}
                                                                    onClick={() => handleDeleteSSN(index)}
                                                                    size={formElementSizesEnum.SMALL}
                                                                    title="Delete"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                    <div hidden={options.taxInfo.length !== 0 && options.taxInfo[0].firstName === applicantInfo?.firstName}>
                                                        <Button
                                                            appearance="outlined"
                                                            iconRight={<span className="utds-icon-before-plus icon-button--small1x" aria-hidden="true" />}
                                                            id={`button-addApps-id`}
                                                            className="button--secondary-color my-spacing-xs"
                                                            onClick={addApplicantTax}
                                                            size={formElementSizesEnum.SMALL}
                                                        >
                                                            Add Applicant Tax Info
                                                        </Button>
                                                    </div>
                                                    <Button
                                                        appearance="outlined"
                                                        iconRight={<span className="utds-icon-before-plus icon-button--small1x" aria-hidden="true" />}
                                                        id={`button-card-addCoapps-id`}
                                                        className="button--secondary-color"
                                                        onClick={() => {setIsModalShown(true);}}
                                                        size={formElementSizesEnum.SMALL}
                                                    >
                                                        Add Co-Applicant Tax Info
                                                    </Button>
                                                </>
                                                : null}
                                            <hr />
                                            <Select
                                                id="propertyRegisteredDmv"
                                                label="Do you or your co-applicants own or lease a motor vehicle or boat that is registered with the Utah DMV?"
                                                value={options?.propertyRegisteredDmv}
                                                isRequired
                                                autoComplete="off"
                                            >
                                                <SelectOptions optionName="propertyRegisteredDmvSelect" value={yesNoList} />
                                            </Select>
                                            {(options.propertyRegisteredDmv === '1' || options.propertyRegisteredDmv === 1) ?
                                                <>
                                                    <Select
                                                        id="dmvAddressUpdate"
                                                        label="Would you like to change the DMV records to reflect your new substitute address?"
                                                        value={options?.dmvAddressUpdate}
                                                        isRequired
                                                        autoComplete="off"
                                                    >
                                                        <SelectOptions optionName="transparentUtahSelect" value={yesNoList} />
                                                    </Select>
                                                    <p className={"application-warning"}>
                                                        NOTE We recommend that you change your DMV records to reflect your new
                                                        substitute address.
                                                    </p>
                                                </>
                                                : null}
                                            {(options.dmvAddressUpdate === '1' || options.dmvAddressUpdate === 1) ?
                                                <div>
                                                    {options?.vehicleList.map((vehicle, index) => (
                                                        <div key={index} className="application__review">
                                                            <div className="application__group2-no-border">
                                                                <TextInput
                                                                    id={vehicle?.vehicleId}
                                                                    name={`firstName`}
                                                                    label="Vehicle owner's first name"
                                                                    value={vehicle?.firstName || ''}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                    isRequired
                                                                    autoComplete="off"
                                                                />
                                                                <TextInput
                                                                    id={vehicle?.vehicleId}
                                                                    name={`lastName`}
                                                                    label="Vehicle owner's last name"
                                                                    value={vehicle?.lastName || ''}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                    isRequired
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <TextInput
                                                                id={vehicle?.vehicleId}
                                                                name={`plateNumber`}
                                                                label="Vehicle plate number"
                                                                value={vehicle?.plateNumber || ''}
                                                                onChange={(e) => handleChange(index, e)}
                                                                maxLength={7}
                                                                isRequired
                                                                autoComplete="off"
                                                            />
                                                            <TextInput
                                                                id={vehicle?.vehicleId}
                                                                name={`vin`}
                                                                label="Vehicle Identification Number (VIN) or Hull Identification Number (HIN)"
                                                                value={vehicle?.vin || ''}
                                                                onChange={(e) => handleChange(index, e)}
                                                                maxLength={17}
                                                                isRequired
                                                                autoComplete="off"
                                                            />
                                                            <div>
                                                                <IconButton
                                                                    appearance="outlined"
                                                                    color="primary"
                                                                    icon={<span className={`utds-icon-before-trash`} aria-hidden="true" />}
                                                                    id="icon-button-sandbox-example-id"
                                                                    onClick={() => handleRemoveFields(index)}
                                                                    size={formElementSizesEnum.SMALL}
                                                                    title="Delete vehicle"
                                                                />
                                                            </div>
                                                        </div>

                                                    ))}
                                                    <div>
                                                        <Button
                                                            appearance="outlined"
                                                            iconRight={<span className={`utds-icon-before-plus icon-button--small1x`} aria-hidden="true" />}
                                                            id={`button-card-addVehicles-id`}
                                                            className="button--secondary-color"
                                                            onClick={handleAddFields}
                                                            size={formElementSizesEnum.SMALL}
                                                        >
                                                            Add Vehicle Info
                                                        </Button>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.file}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                            onSubmit={handleSubmit}/>
                    </main>
                    {isModalShown
                        ? (
                            <div className="modal-backdrop backdrop-dark">
                                <dialog
                                    aria-modal="true"
                                    className="modal__wrapper modal--medium"
                                    id="modal-notes"
                                >
                                    <div className="modal__title">
                                        Select co-applicants you would like to include.
                                    </div>
                                    <div className="modal__content">
                                        <p>If a co-applicant is missing please return to the co-applicant page and add them there.</p>
                                        {coapplicantList.length ?
                                            coapplicantList.map((coapplicant, index) => (
                                                <div key={index}>
                                                    <Checkbox
                                                        id={`checkbox-example-render-id-${index}`}
                                                        label={`${coapplicant.lastName.toUpperCase()}, ${coapplicant.firstName.toUpperCase()}`}
                                                        onChange={() => handleCheckboxChange(index)}
                                                        value={checkState[index] || false}
                                                    />
                                                </div> ))
                                            :"No co-applicants to add."
                                        }
                                    </div>
                                    <div className="modal__footer application__buttons">
                                        <div className="application__buttons-right">
                                            <Button
                                                color="none"
                                                id="button-notes-close"
                                                className="button--primary-color"
                                                onClick={() => setIsModalShown(false)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="application__buttons-right">
                                            <Button
                                                appearance="solid"
                                                color="none"
                                                id="button-notes-okay"
                                                className="button--secondary-color"
                                                onClick={() => handleOkay()}
                                            >
                                                Okay
                                            </Button>
                                        </div>
                                    </div>
                                </dialog>
                            </div>
                        ) : null}
                </div>
            )
    );
}

export default AdditionalOptionsPg;

/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.string,
};
const defaultProps = {};

function Signature({ value }) {
  return (
    value
      ? (
        <div className="application-signature">
          {value}
        </div>
      )
      : ''
  );
}

Signature.propTypes = propTypes;
Signature.defaultProps = defaultProps;

export default Signature;

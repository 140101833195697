const propTypes = {};
const defaultProps = {};

function Page403() {
  return (
    <div>
      <main className="content-width page-not-found__content">
        <div>
          <h1 className="mt-spacing">
            Access Denied
          </h1>
          <br />
          <p>{`Access to the online application has been denied.  If you believe you're receiving this message in error, email khetrick@utah.gov for assistance.`}</p>
        </div>
      </main>
    </div>
  );
}

Page403.propTypes = propTypes;
Page403.defaultProps = defaultProps;

export default Page403;

import './App.css';
import * as React from 'react';
import '@utahdts/utah-design-system-header/css';
import '@utahdts/utah-design-system-header/src/css/index.scss';
import '@utahdts/utah-design-system/css/index.scss';
import './css/index.scss';
import Routing from "./components/Routing";
import {OidcSecure} from "@axa-fr/react-oidc";
import Layout from "./form_pages/Layout.jsx";

import AppContextProvider from "./contexts/AppContextProvider.jsx";
import {UtahDesignSystemContextProvider} from "@utahdts/utah-design-system";


export default function App() {

    return (
        <OidcSecure>
            <UtahDesignSystemContextProvider defaultSettings={{ bannerDuration: 3500 }}>
                <AppContextProvider>
                    <div className="utah-design-system">
                        <div className={"full-width main-template"}>
                            <br/>
                            <Layout/>
                            <Routing/>
                        </div>
                    </div>
                </AppContextProvider>
            </UtahDesignSystemContextProvider>
        </OidcSecure>
    );
}

import * as yup from 'yup';
import 'yup-phone-lite';


export const applicationSchema = yup.object().shape({
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    phone: yup.string().phone('US', 'Please enter a valid phone number.').required(),
    dateOfBirth: yup.string()
        .required('Date of birth is required')
        .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/, 'Invalid date of birth format (mm/dd/yyyy)')
        .test('is-age-18', 'Must be at least 18 years old', function(value) {
            // Parse the date string and calculate the age
            const birthDate = new Date(value);
            const today = new Date();
            const age = today.getFullYear() - birthDate.getFullYear();

            // Adjust age for leap years
            const isBeforeBirthday = today.getMonth() < birthDate.getMonth() ||
                (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

            return age > 18 || (age === 18 && isBeforeBirthday);
        }),
    preferredLanguage: yup.string(),
    email: yup.string().email('Invalid email').notRequired(),
    enrollmentReasons: yup.array().min(1,'Please provide your enrollment reason(s)'),
});

export const coapplicantSchema = yup.object().shape({
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    dateOfBirth: yup.string()
        .required('Date of birth is required')
        .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/, 'Invalid date format (mm/dd/yyyy)')
        .test('is-not-future-date', 'Date of birth must be on or before today\'s date', (value) => {
            if (!value) return true; // if value is not provided, let the required validator handle it
            const dob = new Date(value);
            const now = new Date();
            return dob <= now; // Return true if date of birth is not in the future
        }),
    relationshipToApplicant: yup.string().required("Please provide the co-applicant's relationship to the primary applicant"),
    email: yup.string().email('Invalid email').notRequired(),
});

export const addressSchema = yup.object().shape({
    streetName: yup.string().required('Please provide your street address'),
    streetName2: yup.string().nullable(),
    zip: yup.string().required('Please provide your zip code').test('zipCheck', 'Please enter a valid Utah zip code', function (value) {
        return (value >= 84001 && value <= 84790);
        }),
    city: yup.string().required('Please provide your city'),
    county: yup.string().required(),
});

export const addressOptSchema = yup.object().shape({
    streetName: yup.string().required('Please provide your street address'),
    streetName2: yup.string(),
    zip: yup.string().required('Please provide your zip code'),
    city: yup.string().required('Please provide your city'),
    state: yup.string().required('Please provide your state'),
});

export const applicantSignatureSchema = yup.object().shape({
    signature: yup.string().required('Please check all statements and add signature before continuing')
});

export const coapplicantSignatureSchema = yup.array().of(yup.object().shape({
    signature: yup.string().required('Please have all listed coapplicants sign')
}));

export const assistantSignatureSchema = yup.object().shape({
    assistantSignature: yup.string().required('Please sign before continuing')
});

export const assistantSchema = yup.object().shape({
    agency: yup.string().required("Please provide your agency or organization"),
    phone: yup.string().phone('US', 'Please enter a valid phone number.').required("Please provide a contact number"),
});

export const courtOrderSchema = yup.object().shape({
    court: yup.string().notRequired(),
    judge: yup.string().notRequired(),
    county: yup.string().notRequired(),
    caseNumber: yup.string().notRequired(),
    dateFiled: yup.string()
        .notRequired().transform((value) => (value === ''? null : value))
        .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/, 'Invalid date format (mm/dd/yyyy)')
        .test('is-not-future-date', 'Date filed must be on or before today\'s date', (value) => {
            if (!value) return true; // if value is not provided, let the required validator handle it
            const dob = new Date(value);
            const now = new Date();
            return dob <= now; // Return true if date of birth is not in the future
        }),
    nameOfParties: yup.string().notRequired(),
    caseType: yup.string().notRequired(),
    judgeOrder: yup.string().notRequired(),
    isActive: yup.boolean().notRequired(),
});

export const fileSchema = yup.array().required('Please add at least one file')
        .min(1, 'Click the add button to upload your file.');

export const additionalOptionsSchema = yup.object().shape({
    abuserWorkForGovt: yup.string().notOneOf(['0'], 'All questions must be answered'),
    abuserAgency: yup.string().nullable().when(['abuserWorkForGovt'], {
        is: (abuserWorkForGovt) => abuserWorkForGovt === '1' || abuserWorkForGovt === 1,
        then: () => yup.string().required('Their agency is required'),
    }),
    selfWorkForGovt: yup.string().notOneOf(['0'], 'All questions must be answered'),
    transparentUtah: yup.string().nullable().when('selfWorkForGovt', {
        is: (selfWorkForGovt) => selfWorkForGovt === '1',
        then: () => yup.string().notOneOf(['0'], 'All questions must be answered').required('Transparent Utah selection is required'),
    }),
    selfAgency: yup.string().nullable().when(['transparentUtah'], {
        is: (transparentUtah) => transparentUtah === '1' || transparentUtah === 1,
        then: () => yup.string().required('Your agency is required'),
    }),
    taxAddressUpdate: yup.string().notOneOf(['0'], 'All questions must be answered'),
    taxInfo: yup.array().when(['taxAddressUpdate'], {
        is: (taxAddressUpdate) => taxAddressUpdate === '1' || taxAddressUpdate === 1,
        then: () => yup.array().min(1,'Please add at least one person\'s tax info').of(
            yup.object().shape({
                last4OfSSN: yup.string().required('Last 4 digits of SSN is required')
                    .matches(/^\d{4}$/, 'Last 4 digits of SSN must be exactly 4 numbers'),
            })
        ),
    }),
    propertyRegisteredDmv: yup.string().notOneOf(['0'], 'All questions must be answered'),
    dmvAddressUpdate: yup.string().nullable().when('propertyRegisteredDmv', {
        is: (selfWorkForGovt) => selfWorkForGovt === '1',
        then: () => yup.string().notOneOf(['0'], 'All questions must be answered').required('DMV record update selection is required'),
    }),
    vehicleList: yup.array().when(['dmvAddressUpdate'], {
        is: (dmvAddressUpdate) => dmvAddressUpdate === '1' || dmvAddressUpdate === 1,
        then: () => yup.array().min(1, 'At least one vehicle is required').of(
            yup.object().shape({
                firstName: yup.string().required('First name is required'),
                lastName: yup.string().required('Last name is required'),
                plateNumber: yup.string().required('Plate number is required'),
                vin: yup.string().required('VIN/HIN is required')
                    .matches(/^[A-HJ-NP-Z0-9]+$/, 'VIN/HIN must contain only alphanumeric characters except I, O, Q')
                    .min(12, 'VIN/HIN must be between 12 and 17 characters long').max(17, 'VIN/HIN must be between 12 and 17 characters long'),
            })
        ),
    }),
});

export const validationService = {
    applicationSchema,
    coapplicantSchema,
    applicantSignatureSchema,
    coapplicantSignatureSchema,
    assistantSignatureSchema,
    assistantSchema,
    addressSchema,
    addressOptSchema,
    courtOrderSchema,
    fileSchema,
    additionalOptionsSchema,
};

export const removeTime = (dateTime) => (dateTime ? dateTime.split('T')[0] : dateTime);
export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return '';
};


export const currentDate = () =>{
  const current = new Date();
  const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  console.log(date);
return date
}


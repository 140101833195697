import React, {useContext, useEffect, useRef, useState} from 'react';
import routingUrls from "../enum/routingUrls.js";
import {AppIdContext} from "../contexts/AppContextProvider.jsx"
import {useApi} from "../hooks/useApi.js";
import {
    Button,
    Form, formElementSizesEnum, IconButton,
    MultiSelect,
    MultiSelectOption,
    Select,
    Spinner,
    TextInput, Tooltip, useBanner,
} from "@utahdts/utah-design-system";
import apiUrls from "../enum/apiUrls.js";
import {useImmer} from "use-immer";
import SelectOptions from "../elements/SelectOptions.jsx";
import {PatternFormat} from "react-number-format";
import AppNavigation from "../elements/AppNavigation.jsx";
import {useNavigate} from "react-router-dom";
import {applicationSchema} from "../services/validationService.js";
import {buildBanner} from "../services/bannerService.jsx";

function ApplicationInfoPg() {

    const apiAddr  = useApi();
    const { addBanner } = useBanner();
    const navigate = useNavigate();
    const { appState: { appId, returnReview }, setAppState } = useContext(AppIdContext);
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useImmer({
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        dateOfBirth: "",
        formerLegalNames: [
            {
                id: "",
                value: ""
            }
        ],
        safeLabelName: "",
        email: "",
        preferredLanguage: "",
        gender: 0,
        pronoun: 0,
        enrollmentReasons: []
    });
    const [genderList, setGenderList] = useState([]);
    const [pronounList, setPronounList] = useState([]);
    const [enrollmentReasonList, setEnrollmentReasonList] = useState([]);
    const safeLabelName = useRef();

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    useEffect(()=>{
        apiAddr.get(apiUrls.genderList)
            .then(genderResponse => {
                setGenderList(genderResponse.data);
            });
        apiAddr.get(apiUrls.pronounList)
            .then(pronounResponse => {
                setPronounList(pronounResponse.data);
            });
        apiAddr.get(apiUrls.enrollmentReasonList)
            .then(enrollmentResponse => {
                setEnrollmentReasonList(enrollmentResponse.data);
            });
        if(appId !== undefined) {
            apiAddr.get(apiUrls.applicationGetById + appId)
                .then(applicationResponse => {
                    setApplication(applicationResponse.data);
                    if(applicationResponse.data.formerLegalNames.length === 0) {
                        handleAddFields();
                    }
                });
        }
        setLoading(false);
        // eslint-disable-next-line
    },[] );

    const handleChange = (name, value) => {
        setApplication({...application, [name] : Array.isArray(value) ? [...value] : value});
    }

    const handleSubmit = async () => {
        try {
            await applicationSchema.validate(application);
            await apiAddr.post(apiUrls.applicationNew, application)
                .then(function (response) {
                    handleAppId(response.data)
                    navigate(returnReview === 1 ? routingUrls.review : routingUrls.address);
                });
        } catch (errors) {
            errorBanner(errors.message);
        }
    };

    const handleAppId = (id) => {
        setAppState((appState) => {appState.appId = id;});
        sessionStorage.setItem('appId', id);
    }

    const handleAddFields = () => {
        setApplication((draft) => {
            draft.formerLegalNames.push({ id: "", value: "" });
        });
    };

    const handleRemoveFields = (index) => {
        setApplication((draft) => {
            draft.formerLegalNames.splice(index, 1);
        });
    };

    const handleLegalNameChange = (index, { id, value }) => {
        setApplication(draft => {
            draft.formerLegalNames[index] = { id, value };
        });
    };

    // The CSS for the Utah Design System is scoped to the
    // 'utah-design-system' class and is optional if you
    // prefer cooking your own.
    return (
        loading
        ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Applicant Information
                                </h1>
                                <br/>
                                <p className="application__vertical-max50">Primary Applicant must be 18 or older. If the victim is a child or incapacitated adult,
                                    their parent or legal guardian will apply as the Primary Applicant and list the victim as a Co-Applicant.</p>
                                <div className={"application-title"}>
                                    <h4>Primary Applicant</h4>
                                </div>
                                <Form id={"applicant"}
                                      state={application}
                                      setState={setApplication}
                                      autoComplete={"hidden"}
                                >
                                    <div className = "application__vertical">
                                        <TextInput
                                            id={"firstName"}
                                            name={"firstName"}
                                            label={"First Name"}
                                            isDisabled={false}
                                            maxLength={30}
                                            value={application?.firstName}
                                            autoComplete="off"
                                            isRequired
                                        />
                                        <TextInput
                                            id="middleName"
                                            name={"middleName"}
                                            label="Middle Name"
                                            isDisabled={false}
                                            maxLength={30}
                                            value={application?.middleName}
                                            autoComplete="off"
                                        />
                                        <TextInput
                                            id="lastName"
                                            name={"lastName"}
                                            label="Last Name"
                                            isDisabled={false}
                                            maxLength={30}
                                            value={application?.lastName}
                                            autoComplete="off"
                                            isRequired
                                        />
                                        <div className={"input-wrapper input-wrapper--text-input"}>
                                            <label>Phone<span className={"required-star"}>*</span></label>
                                            <PatternFormat format={"(###) ###-####"}
                                                           placeholder={"(###) ###-####"}
                                                           className={"PatternFormat"}
                                                           name={"phone"}
                                                           onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                           autoComplete="off"
                                                           value={application?.phone}
                                            />
                                        </div>
                                        <div className={"input-wrapper input-wrapper--text-input"}>
                                            <label>Date Of Birth<span className={"required-star"}>*</span></label>
                                            <PatternFormat format={"##/##/####"}
                                                           placeholder={"mm/dd/yyyy"}
                                                           className={"PatternFormat"}
                                                           name={"dateOfBirth"}
                                                           onChange={(e) => handleChange(e.target.name,e.target.value)}
                                                           autoComplete="off"
                                                           value={application?.dateOfBirth}
                                            />
                                        </div>
                                        <TextInput
                                            id="safeLabelName"
                                            name={"safeLabelName"}
                                            label="Safe Label Name"
                                            maxLength={30}
                                            isDisabled={false}
                                            value={application?.safeLabelName}
                                            autoComplete="off"
                                            innerRef={safeLabelName}

                                        />
                                        <Tooltip className = {'application__vertical-max35'}
                                            referenceElement={safeLabelName.current}
                                        >
                                            A Safe Label name is a fake name that Safe at Home
                                            will send the mail under to the applicant’s true address.
                                            Applicant’s cannot receive mail at their substitute address in the Safe Label Name.
                                            We encourage applicants to make up a Safe Label name as
                                            one more way to keep their name and true address disconnected.
                                        </Tooltip>


                                        <div>
                                            {application?.formerLegalNames.map((legalName, index) => (
                                                <div key={index} className={"application__legal-names"}>
                                                    <div className="application__legal-names-content">
                                                        <TextInput
                                                            id={legalName?.id}
                                                            name={`formerLegalName-${index}`}
                                                            label="Former Legal Name"
                                                            value={legalName?.value || ''}
                                                            autoComplete="off"
                                                            onChange={(e) => handleLegalNameChange(index, {
                                                                id: e.target.id,
                                                                value: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={"application__legal-names-button"}>
                                                        <IconButton
                                                            appearance="outlined"
                                                            color="primary"
                                                            icon={<span className={`utds-icon-before-trash`} aria-hidden="true" />}
                                                            id="icon-button-sandbox-example-id"
                                                            isDisabled={application?.formerLegalNames.length === 1 }
                                                            onClick={() => handleRemoveFields(index)}
                                                            size={formElementSizesEnum.SMALL}
                                                            title="Delete former legal name"
                                                        />
                                                    </div>
                                                </div>

                                            ))}
                                            <div className={"application__vertical-div"}>
                                                <Button
                                                    appearance="outlined"
                                                    iconRight={<span className={`utds-icon-before-plus`} aria-hidden="true" />}
                                                    id={`button-card-addfln-id`}
                                                    className="button--secondary-color"
                                                    onClick={handleAddFields}
                                                    size={formElementSizesEnum.SMALL}
                                                >
                                                    Add A Former Legal Name
                                                </Button>
                                            </div>
                                        </div>
                                        <TextInput
                                            id="email"
                                            name={"email"}
                                            label="Email"
                                            isDisabled={false}
                                            value={application?.email}
                                            autoComplete="off"
                                        />
                                        <TextInput
                                            id="preferredLanguage"
                                            name={"preferredLanguage"}
                                            label="Preferred Language"
                                            isDisabled={false}
                                            maxLength={30}
                                            value={application?.preferredLanguage}
                                            autoComplete="off"
                                        />
                                        <Select
                                            id="gender"
                                            name={"gender"}
                                            label="Gender"
                                            isDisabled={false}
                                            value={application?.gender.value}
                                            autoComplete="off"
                                        >
                                            <SelectOptions optionName="genderOptions" value={genderList} />
                                        </Select>
                                        <Select
                                            id="pronoun"
                                            name={"pronoun"}
                                            label="Pronouns"
                                            isDisabled={false}
                                            value={application?.pronoun.value}
                                            autoComplete="off"
                                        >
                                            <SelectOptions optionName="pronounOptions" value={pronounList} />
                                        </Select>
                                        <MultiSelect
                                            id="enrollmentReasons"
                                            name={"enrollmentReasons"}
                                            label="Enrollment Reason(s)"
                                            isDisabled={false}
                                            isRequired
                                            placeholder={"Select..."}
                                            onChange={(e) => handleChange("enrollmentReasons",e)}
                                            values={application?.enrollmentReasons}
                                            autoComplete="off"
                                        >
                                            {enrollmentReasonList?.map((item) => (
                                                <MultiSelectOption
                                                    key={`enrollmentReason-${item.id}`}
                                                    value={item.id}
                                                    label={item.value}
                                                />
                                            ))}
                                        </MultiSelect>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <AppNavigation
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.landing}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                            onSubmit={handleSubmit}
                        />
                    </main>
                </div>

            )
    );
}

export default ApplicationInfoPg;

import '../App.css';

import '@utahdts/utah-design-system-header/css';

import {useApi} from "../hooks/useApi.js";
import routingUrls from "../enum/routingUrls.js";
import React, {useContext, useEffect, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useImmer} from "use-immer";
import apiUrls from "../enum/apiUrls.js";
import {
    ComboBox,
    Form,
    Spinner,
    TextInput, useBanner
} from "@utahdts/utah-design-system";
import AppNavigation from "../elements/AppNavigation.jsx";
import {buildBanner} from "../services/bannerService.jsx";
import {addressOptSchema, addressSchema} from "../services/validationService.js";
import {useNavigate} from "react-router-dom";
import ComboBoxOptions from "../elements/ComboBoxOptions.jsx";

function AddressPg(){

    const navigate = useNavigate();
    const { appState: { appId, returnReview } } = useContext(AppIdContext);
    const apiAddr  = useApi();
    const { addBanner } = useBanner();
    const [loading, setLoading] = useState(true);
    const [countyList, setCountyList] = useImmer([]);
    const[
        applicantActualForm,
        setApplicantActualForm
    ] = useImmer({
        applicantAddressId: "",
        applicationId: appId,
        addressTypeId: 1,
        streetName: "",
        streetName2: "",
        city: "",
        state: "UT",
        zip: "",
        county: "",
        isActive: 0
    });

    const[
        applicantOptionalForm,
        setApplicantOptionalForm
    ] = useImmer({
        applicantAddressId: "",
        applicationId: appId,
        addressTypeId: 2,
        streetName: "",
        streetName2: "",
        city: "",
        state: "",
        zip: "",
        county: "",
        isActive: 0
    });

    const errorBanner = (errorMessage) => (addBanner(buildBanner('error', errorMessage)));

    const handleSubmit = async () => {
        try {
            await handleAddressSubmit(applicantActualForm);
            await handleAddressSubmit(applicantOptionalForm);
            navigate(returnReview === 1 ? routingUrls.review : routingUrls.coapp);
        } catch (errors) {
            errorBanner(errors.message);
        }
    };

    const handleAddressSubmit = async (form) => {
        if (form.addressTypeId === 1 || form.streetName !== "") {
            const schema = form.addressTypeId === 1 ? addressSchema : addressOptSchema;
            const url = form.applicantAddressId ? apiUrls.addressUpdate : apiUrls.applicantAddressInsert;
            await schema.validate(form);
            await apiAddr.post(url, form);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const uppercaseValue = value.toUpperCase();
        setApplicantOptionalForm({...applicantOptionalForm, [name]: uppercaseValue});
    }

    useEffect(() => {
        (async () => {
            const [
                actualAddressResponse,
                optionalAddressResponse,
                countyListResponse
            ] = await Promise.all([
                apiAddr.get(apiUrls.addressActual + appId),
                apiAddr.get(apiUrls.addressOptional + appId),
                apiAddr.get(apiUrls.countyList)
            ]);

            setApplicantActualForm(actualAddressResponse.data.isActive !== undefined ? actualAddressResponse.data : applicantActualForm);
            setApplicantOptionalForm(optionalAddressResponse.data.isActive !== undefined ? optionalAddressResponse.data : applicantOptionalForm);
            setCountyList(countyListResponse.data);
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, []);

    return(
        loading
            ?<Spinner/>
            :(
                <div className={"full-width"}>
                    <main className={"content-width"}>
                        <div>
                            <div className={"application"}>
                                <h1 className={"mt-spacing"}>
                                    Applicant Address
                                </h1>
                                <div className={"application__group2"}>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Residential Address</h4>
                                        </div>
                                        <Form className={"address"}
                                              state={applicantActualForm}
                                              setState={setApplicantActualForm}
                                              autoComplete={"hidden"}
                                        >
                                            <div className={"application__vertical"}>
                                                <TextInput
                                                    id={"streetName"}
                                                    name={"streetName"}
                                                    label={"Street Address"}
                                                    value={applicantActualForm?.streetName}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"streetName2"}
                                                    name={"streetName2"}
                                                    label={"Street Address 2"}
                                                    value={applicantActualForm?.streetName2}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"city"}
                                                    name={"city"}
                                                    label={"City"}
                                                    value={applicantActualForm?.city}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"zip"}
                                                    name={"zip"}
                                                    label={"Zip"}
                                                    value={applicantActualForm?.zip}
                                                    maxLength={5}
                                                    isRequired
                                                    autoComplete="off"
                                                />
                                                <ComboBox
                                                    id={"county"}
                                                    name={"county"}
                                                    label={"County"}
                                                    placeholder={"Search or Select..."}
                                                    value={applicantActualForm?.county}
                                                    isRequired
                                                    autoComplete="off"
                                                >
                                                    <ComboBoxOptions optionName={"counties"} value={countyList}/>
                                                </ComboBox>
                                            </div>
                                        </Form>
                                    </div>
                                    <div>
                                        <div className={"application-title"}>
                                            <h4>Optional Mailing Address</h4>
                                        </div>
                                        <Form className={"address"}
                                              state={applicantOptionalForm}
                                              setState={setApplicantOptionalForm}>
                                            <div className={"application__vertical"}>
                                                <TextInput

                                                    id={"streetName"}
                                                    name={"streetName"}
                                                    label={"Street Address"}
                                                    value={applicantOptionalForm?.streetName}
                                                    isRequired={!!applicantOptionalForm?.streetName}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"streetName2"}
                                                    name={"streetName2"}
                                                    label={"Street Address 2"}
                                                    value={applicantOptionalForm?.streetName2}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"city"}
                                                    name={"city"}
                                                    label={"City"}
                                                    value={applicantOptionalForm?.city}
                                                    isRequired={!!applicantOptionalForm?.streetName}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"state"}
                                                    name={"state"}
                                                    label={"State"}
                                                    value={applicantOptionalForm?.state.toUpperCase()}
                                                    onChange={(e) => handleChange(e)}
                                                    maxLength={2}
                                                    placeholder="Ex. UT"
                                                    isRequired={!!applicantOptionalForm?.streetName}
                                                    autoComplete="off"
                                                />
                                                <TextInput
                                                    id={"zip"}
                                                    name={"zip"}
                                                    label={"Zip"}
                                                    maxLength={5}
                                                    value={applicantOptionalForm?.zip}
                                                    isRequired={!!applicantOptionalForm?.streetName}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <br/>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AppNavigation
                            nextIcon={<span className={`utds-icon-before-arrow-right`} aria-hidden="true" />}
                            nextLabel={returnReview === 1 ? "Save & Return" : "Save & Continue"}
                            onBack={routingUrls.info}
                            backIcon={<span className={`utds-icon-before-arrow-left`} aria-hidden="true" />}
                            backLabel={"Back"}
                            onSubmit={handleSubmit}/>
                    </main>
                </div>
            )

    )

}
export default AddressPg;

/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import {
    Button, formElementSizesEnum,
    formElementSizesEnum as FormElementSizes, IconButton,
    Table,
    TableBody,
    TableBodyData,
    TableBodyDataCellTemplate,
    TableBodyDataRowTemplate,
    TableHead,
    TableHeadCell,
    TableHeadRow, TablePagination,
    TableWrapper
} from '@utahdts/utah-design-system';
import { useNavigate } from 'react-router-dom';
import routingUrls from "../enum/routingUrls.js";
import React, {useContext, useState} from "react";
import {AppIdContext} from "../contexts/AppContextProvider.jsx";
import {useApi} from "../hooks/useApi.js";
import apiUrls from "../enum/apiUrls";
import ConfirmationModal from "./ConfirmationModal.jsx";

const propTypes = {
    tableName: PropTypes.string.isRequired,
    tableList: PropTypes.arrayOf(PropTypes.shape({
        applicationId: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        applicationDate: PropTypes.string.isRequired,
    })),

};
const defaultProps = {};

function IncompleteCard({ tableName, tableList, onDelete}) {

    const apiAddr = useApi();
    const {setAppState} = useContext(AppIdContext);
    const navigate = useNavigate();
    const [isModalShown, setIsModalShown] = useState(null);

    const handleEdit = (value) => {
        setAppState((appState) => {
            appState.appId = value;
        });
        sessionStorage.setItem('appId', value);
        navigate(routingUrls.info);
    };

    const handleDelete = async (applicationId) => {
        await apiAddr.delete(apiUrls.applicationDelete+applicationId)
            .then(() => {
                onDelete();
            });
        setIsModalShown(null);
    }

    return (
        tableList
            ? (
                <TableWrapper id={`${tableName}-table`}>
                    <Table className="table table--lines-x table--alt">
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell recordFieldPath="applicationId">Application ID</TableHeadCell>
                                <TableHeadCell recordFieldPath="lastName">Last Name</TableHeadCell>
                                <TableHeadCell recordFieldPath="applicationDate">Date</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyData records={tableList} recordIdField="applicationId">
                                <TableBodyDataRowTemplate>
                                    <TableBodyDataCellTemplate recordFieldPath="applicationId" />
                                    <TableBodyDataCellTemplate recordFieldPath="lastName" />
                                    <TableBodyDataCellTemplate recordFieldPath="applicationDate" />
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <Button
                                                    appearance="solid"
                                                    color="none"
                                                    iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                                    id={`button-card-${record.applicationId}-id`}
                                                    className="button--secondary-color"
                                                    onClick={() => {handleEdit(record.applicationId)}}
                                                    size={FormElementSizes.SMALL}
                                                >
                                                    Complete
                                                </Button>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <>
                                                    <IconButton
                                                        appearance="outlined"
                                                        color="primary"
                                                        icon={<span className={`utds-icon-before-trash`} aria-hidden="true" />}
                                                        id={`application-${record.applicationId}`}
                                                        onClick={() => setIsModalShown(record.applicationId)}
                                                        size={formElementSizesEnum.SMALL}
                                                        title="Delete application"
                                                    />
                                                    {isModalShown === record.applicationId &&
                                                        <ConfirmationModal
                                                            onOkay={() => handleDelete(record.applicationId)}
                                                            onClose={() => setIsModalShown(null)}
                                                        />
                                                    }
                                                </>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                </TableBodyDataRowTemplate>
                            </TableBodyData>
                        </TableBody>
                    </Table>
                    <TablePagination
                        className="mt-spacing"
                        id="table-pagination-id"
                        itemsPerPage={3}
                    />
                </TableWrapper>
            )
            : ''
    );
}

IncompleteCard.propTypes = propTypes;
IncompleteCard.defaultProps = defaultProps;

export default IncompleteCard;

import PropTypes from 'prop-types';
import React, {createContext, useEffect, useMemo} from 'react';
import { useImmer } from 'use-immer';

/** @typedef {import ('../../mono-repo-globals/@types/jsdoc.d.js').AppContextValue} AppContextValue */

const propTypes = { children: PropTypes.node.isRequired };

const defaultProps = {};

const AppIdContext = createContext({
    appState: { appId: undefined, addrConfUser: undefined, addrConfUserLoadingState: undefined, returnReview: 0 },
    setAppState: () => { },
});

function AppContextProvider({ children }) {
    const [appState, setAppState] = useImmer(() => ({ appId: undefined }));
    const contextState = useMemo(
        () => ({
            // app state
            appState,
            setAppState,
        }),
        [appState, setAppState]
    );

    useEffect(() => {
        // Load the ID from sessionStorage
        const storedId = sessionStorage.getItem('appId');

        if (storedId) {
            // If ID is found in sessionStorage, update the app state with it
            setAppState(prevState => ({
                ...prevState,
                appId: storedId
            }));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AppIdContext.Provider value={contextState}>
            {children}
        </AppIdContext.Provider>
    );
}
AppContextProvider.propTypes = propTypes;
AppContextProvider.defaultProps = defaultProps;

export default AppContextProvider;
export { AppIdContext };

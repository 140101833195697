import React, {useEffect, useState} from "react";
import {Checkbox} from "@utahdts/utah-design-system";

function AffirmationQuestionList  ({signed, onAllChecked, questionList})  {
    const [state, setState] = useState([]);

    useEffect(()=> {
        if(signed) {
            setState(new Array(questionList.length).fill(true));
            onAllChecked(true)
        } else setState(new Array(questionList.length).fill(false));
        // eslint-disable-next-line
    },[]);

    const handleChange = (index) => {
        const newState = [...state];
        newState[index] = !newState[index];
        setState(newState);

        const allChecked = newState.every((isChecked) => isChecked);
        onAllChecked(allChecked);
    }

    return (
        <fieldset>
            {questionList?.map((questions,index)=>
                    <Checkbox
                        className={"application-checkbox"}
                        key={`affirmationQuestion-${questions.id}`}
                        onChange={() => handleChange(index)}
                        label={questions.value}
                        isDisabled={signed}
                        value={state[index]}
                    />
            )}
        </fieldset>
    );
}

export default AffirmationQuestionList;

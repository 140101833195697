import PropTypes from 'prop-types';
import { Button } from '@utahdts/utah-design-system';
import React from 'react';

const propTypes = {
    onOkay: PropTypes.func,
    onClose: PropTypes.func,
};

function ConfirmationModal({
                               onOkay,
                               onClose,
                           }) {
    const handleOkay = () => {
        onOkay();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div className="modal-backdrop backdrop-dark">
            <dialog
                aria-modal="true"
                className="modal__wrapper modal--small"
                id="modal-example"
            >
                <div className="modal__title">Warning</div>
                <div className="modal__content">
                    You are deleting an application. Continue?
                </div>
                <div className="modal__footer application__buttons">
                    <div className="application__buttons-right">
                        <Button
                            appearance="solid"
                            color="none"
                            id="button-notes-okay"
                            className="button--secondary-color"
                            onClick={() => handleOkay()}
                        >
                            Yes
                        </Button>
                    </div>
                    <div className="application__buttons-right">
                        <Button
                            color="none"
                            id="button-notes-close"
                            className="button--primary-color"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <button
                    className="button icon-button modal__close-button icon-button--borderless icon-button--small"
                    type="button"
                    onClick={handleClose}
                >
          <span
              className="utds-icon-before-x-icon"
              aria-hidden="true"
          />
                    <span className="visually-hidden">
            Close modal
          </span>
                </button>
            </dialog>
        </div>
    );
}
ConfirmationModal.propTypes = propTypes;

export default ConfirmationModal;

import {ComboBoxOption} from "@utahdts/utah-design-system";
import PropTypes from "prop-types";

const propTypes = {
    optionName: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        isActive: PropTypes.number,
    })),

};
const defaultProps = {};

function ComboBoxOptions ({ optionName, value }) {
    return(
        value
        ? (
            value.map((item) => (
                <ComboBoxOption
                    key={`${optionName}-${item.id}`}
                    value={item.id}
                    label={item.value}
                />
            ))
            )
            : ''
    );
}

ComboBoxOptions.propTypes = propTypes;
ComboBoxOptions.defaultProps = defaultProps;

export default ComboBoxOptions;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    Select,
    TextInput, Form, Button,
} from '@utahdts/utah-design-system';
import SelectOptions from './SelectOptions.jsx';
import routingUrls from "../enum/routingUrls.js";
import {useNavigate} from "react-router-dom";
import React from "react";

const propTypes = {
    genderList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        isActive: PropTypes.number,
    })),
    pronounList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        isActive: PropTypes.number,
    })),
    coapplicantList: PropTypes.arrayOf(PropTypes.shape({
        coapplicantId: PropTypes.number,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.string,
        relationshipToApplicant: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        signature: PropTypes.string,
        gender: PropTypes.number,
        pronouns: PropTypes.number,
        isActive: PropTypes.number,
    })),
    state: PropTypes.object,
    setState: PropTypes.func,

};
const defaultProps = {};

function Coapplicant({
                         coapplicantList,
                         genderList,
                         pronounList,
                         state,
                         setState,
                     }) {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(routingUrls.coapp);
    };

    return (
        coapplicantList.length
            ? (
                coapplicantList.map((coapplicant, index) => (
                    <div key={`coapplicant-${coapplicant.coapplicantId}`}  className={"application__review-component"}>
                        <div className="application-title">
                            <h4>Co-Applicant</h4>
                        </div>
                        <Form id={`coapplicant-form-${coapplicant.coapplicantId}`} state={state} setState={setState}>
                            <div className="application__review-group">
                                <TextInput
                                    id={`coapplicantList.${index}.firstName`}
                                    label="First Name"
                                    isDisabled={true}
                                    value={coapplicant?.firstName}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.middleName`}
                                    label="Middle Name"
                                    isDisabled={true}
                                    value={coapplicant?.middleName}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.lastName`}
                                    label="Last Name"
                                    isDisabled={true}
                                    value={coapplicant?.lastName}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.dateOfBirth`}
                                    label="Date of Birth"
                                    isDisabled={true}
                                    value={coapplicant?.dateOfBirth}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.relationshipToApplicant`}
                                    label="Relationship To Applicant"
                                    isDisabled={true}
                                    value={coapplicant?.relationshipToApplicant}
                                />
                                <Select
                                    id={`coapplicantList.${index}.gender`}
                                    label="Gender"
                                    isDisabled={true}
                                    value={coapplicant?.gender}
                                >
                                    <SelectOptions optionName={`coapplicantList.${index}.genderOptions`} value={genderList} />
                                </Select>
                                <Select
                                    id={`coapplicantList.${index}.pronouns`}
                                    label="Pronouns"
                                    isDisabled={true}
                                    value={coapplicant?.pronoun}
                                >
                                    <SelectOptions optionName={`coapplicantList.${index}.pronounOptions`} value={pronounList} />
                                </Select>
                                <TextInput
                                    id={`coapplicantList.${index}.phone`}
                                    label="Phone"
                                    isDisabled={true}
                                    value={coapplicant?.phone}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.email`}
                                    label="Email"
                                    isDisabled={true}
                                    value={coapplicant?.email}
                                />
                                <TextInput
                                    id={`coapplicantList.${index}.signature`}
                                    label="Signature"
                                    isDisabled
                                    value={coapplicant?.signature}
                                />
                                <div/>
                                <div className="application__group-bottom-right">
                                    <Button
                                        iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                        appearance="solid"
                                        color="secondary"
                                        id="button-applicant"
                                        onClick={handleSubmit}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                ))
            )
            : (
                <>
                    <div className="application-title">
                        <h4>Co-Applicant</h4>
                    </div>
                    <div className={"application__group"}>
                        <p>No Records Found</p>
                        <div className="application__group-bottom-right">
                            <Button
                                iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                appearance="solid"
                                color="secondary"
                                id="button-applicant"
                                onClick={handleSubmit}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </>
            )
    );
}

Coapplicant.propTypes = propTypes;
Coapplicant.defaultProps = defaultProps;

export default Coapplicant;

/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import {
    Button, ConfirmationButton, ConfirmationChildren,
    formElementSizesEnum as FormElementSizes, InitialChildren,
    Table,
    TableBody,
    TableBodyData,
    TableBodyDataCellTemplate,
    TableBodyDataRowTemplate,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    TableWrapper
} from '@utahdts/utah-design-system';
import React from "react";
import {useApi} from "../hooks/useApi.js";

const propTypes = {
    tableName: PropTypes.string.isRequired,
    tableList: PropTypes.arrayOf(PropTypes.shape({
        caseNumber: PropTypes.string.isRequired,
        namesOfParties: PropTypes.string.isRequired,
        dateFiled: PropTypes.string,
    })),

};
const defaultProps = {};

function CourtOrderCard({ tableName, tableList, onEdit, onDelete }) {

    const apiAddr = useApi();
    const handleEdit = (courtOrderId) => {
        if (onEdit) {
            onEdit(courtOrderId);
        }
    }

    const handleConfirmDelete = (courtOrderId) => {
        // Implement delete functionality based on the passed id
        if (onDelete) {
            apiAddr.delete(`/court-order/delete/${courtOrderId}`)
                .then(() => {
                    onDelete();
                });
        }
    };

    return (
        tableList
            ? (
                <TableWrapper id={`${tableName}-table`}>
                    <Table className="table table--lines-x table--alt">
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell recordFieldPath="caseNumber">Case Number</TableHeadCell>
                                <TableHeadCell recordFieldPath="namesOfParties">Names of Parties</TableHeadCell>
                                <TableHeadCell recordFieldPath="dateFiled">Date Filed</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyData records={tableList} recordIdField="courtOrderId">
                                <TableBodyDataRowTemplate>
                                    <TableBodyDataCellTemplate recordFieldPath="caseNumber" />
                                    <TableBodyDataCellTemplate recordFieldPath="namesOfParties" />
                                    <TableBodyDataCellTemplate recordFieldPath="dateFiled" />
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <Button
                                                    appearance="solid"
                                                    color="none"
                                                    iconRight={<span className={`utds-icon-before-edit`} aria-hidden="true" />}
                                                    id={`button-card-${record.courtOrderId}-id`}
                                                    className="button--secondary-color"
                                                    onClick={() => {handleEdit(record.courtOrderId)}}
                                                    size={FormElementSizes.SMALL}
                                                >
                                                    Edit
                                                </Button>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                    <TableBodyDataCellTemplate>
                                        {
                                            ({record}) => (
                                                <ConfirmationButton
                                                    appearance="outlined"
                                                    color="primary"
                                                    confirmationColor={"accent"}
                                                    id={`button-card-${record.courtOrderId}-id`}
                                                    onClick={() => {handleConfirmDelete(record.courtOrderId)}}
                                                    size={FormElementSizes.SMALL}
                                                >
                                                    <InitialChildren>Delete</InitialChildren>
                                                    <ConfirmationChildren>Are you sure?</ConfirmationChildren>
                                                </ConfirmationButton>
                                            )
                                        }
                                    </TableBodyDataCellTemplate>
                                </TableBodyDataRowTemplate>
                            </TableBodyData>
                        </TableBody>
                    </Table>
                </TableWrapper>
            )
            : ''
    );
}

CourtOrderCard.propTypes = propTypes;
CourtOrderCard.defaultProps = defaultProps;

export default CourtOrderCard;

export const buildBanner = (type, message) => {
    switch (type) {
        case 'success':
            return {
                position: 'top',
                className: 'banner--success banner--medium',
                icon: <span className="utds-icon-before-check" aria-hidden="true" />,
                message,
            };
        case 'error':
            return {
                position: 'top',
                className: 'banner--danger banner--medium',
                icon: <span className="utds-icon-before-warning" aria-hidden="true" />,
                message,
            };
        default:
            return {
                position: 'top',
                className: 'banner--medium',
                message,
            };
    }
};

export const bannerService = {
    buildBanner,
};

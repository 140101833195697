const apiUrls = {
    loginInfo: '/loginInfo',
    additionalOption: '/additional-option/',
    taxList: '/individual/get-individual/',
    dmvList: '/vehicle/get-vehicle-id/',
    additionalOptionNew: '/additional-option/insert-new',
    additionalOptionUpdate: '/additional-option/update',
    individualList: '/individual/get-individual/',
    individualDelete: '/individual/delete/',
    vehicleDelete: '/vehicle/delete/',
    addressActual: '/address/address-app-id/1/',
    addressOptional: '/address/address-app-id/2/',
    addressTypeList: '/address/list',
    addressUpdate: '/address/update',
    affirmationQuestions: '/affirmation-question/list',
    applicantAddressInsert: '/address/insert-new',
    applicantGet: '/applicant/get-applicant/',
    applicationDelete: '/application/delete/',
    applicationGet: '/application/',
    applicationGetById: '/application/get-application-id/',
    applicationNew: '/application/insert-new',
    applicationStatusList: '/application-status/list',
    applicationIncompleteList: '/application-status/pending-applications',
    assistantById: '/member/',
    caseTypeList: '/court-order/case-type/list',
    coAppList: '/coapplicant/coapplication-list/',
    coAppListUpdate: '/coapplicant/update-list',
    complete: '/application/complete-app/',
    confirmationList: '/confirmation/list',
    countyList: '/county/list',
    courtOrder: '/court-order/',
    courtOrderList: '/court-order/list/',
    courtOrderNew: '/court-order/insert-new',
    enrollmentReasonList: '/enrollment-reason/list',
    fileList: '/offense-document/list/',
    fileSubmit: '/offense-document/upload',
    genderList: '/gender/list',
    places: '/applicant/signature',
    pronounList: '/pronoun/list',
    signature: '/applicant/signature',
    memberUpdate: '/member/update',
    optionInfoGet: '/additional-option/info/',
}
export default apiUrls;

import {Navigate, Route, Routes} from 'react-router-dom';
import useUtahHeader from '../hooks/useUtahHeader';
import LandingPg from "../form_pages/LandingPg.jsx";
import ApplicationInfoPg from "../form_pages/ApplicationInfoPg.jsx";
import AddressPg from "../form_pages/AddressPg.jsx"
import FileSubmission from "../form_pages/FileSubmission";
import AffirmationPg from "../form_pages/AffirmationPg.jsx";
import AssistantRec from "../form_pages/AssistantRec";
import * as React from "react";
import routingUrls from "../enum/routingUrls.js";
import CompletionPg from "../form_pages/CompletionPg";
import ReviewPage from "../form_pages/ReviewPage";
import CoapplicantPg from "../form_pages/CoapplicantPg.jsx";
import CourtOrderPg from "../form_pages/CourtOrderPg";
import AdditionalOptionsPg from "../form_pages/AdditionalOptionsPg.jsx";
import {RoleRoute} from "./RoleRoute";

const propTypes = {};
const defaultProps = {};

function Routing() {
    useUtahHeader();

    return (
        <Routes>
            <Route path={routingUrls.landing}
                   element={
                       <RoleRoute>
                           <LandingPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.info}
                   element={
                       <RoleRoute>
                           <ApplicationInfoPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.address}
                   element={
                       <RoleRoute>
                           <AddressPg/>
                       </RoleRoute>}/>
            <Route path={routingUrls.coapp}
                   element={
                       <RoleRoute>
                           <CoapplicantPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.court}
                   element={
                       <RoleRoute>
                           <CourtOrderPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.file}
                   element={
                       <RoleRoute>
                           <FileSubmission/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.options}
                   element={
                       <RoleRoute>
                           <AdditionalOptionsPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.affirm}
                   element={
                       <RoleRoute>
                           <AffirmationPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.rec}
                   element={
                       <RoleRoute>
                           <AssistantRec/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.review}
                   element={
                       <RoleRoute>
                           <ReviewPage/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.comp}
                   element={
                       <RoleRoute>
                           <CompletionPg/>
                       </RoleRoute>
                   }/>
            <Route path={routingUrls.home} element={<Navigate to={routingUrls.landing} replace />}
            />
        </Routes>
    );
}

Routing.propTypes = propTypes;
Routing.defaultProps = defaultProps;

export default Routing;

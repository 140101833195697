import { useEffect, useState } from 'react';
import { useApi } from './useApi.js';
import apiUrls from "../enum/apiUrls.js";

export const AddrConfUserStatus = {
  Unauthorized: 'Unauthorized',
  Loading: 'Loading user',
  Loaded: 'User loaded',
  LoadingError: 'Error loading user',
};

export const useAddrConfUser = () => {
  const [addrConfUser, setAddrConfUser] = useState({ user: null, status: AddrConfUserStatus.Unauthorized });
  const [addrConfUserId, setAddrConfUserId] = useState('');
  const apiAddr = useApi();

  useEffect(() => {
    let isMounted = true;
    if (apiAddr) {
      setAddrConfUser({ ...addrConfUser, status: AddrConfUserStatus.Loading });
      apiAddr.get(apiUrls.loginInfo)
        .then((info) => {
          if (isMounted) {
            const { data } = info;
            setAddrConfUser({ user: data, status: AddrConfUserStatus.Loaded });
          }
        })
        .catch(() => setAddrConfUser({ ...addrConfUser, status: AddrConfUserStatus.LoadingError }));
    } else {
      setAddrConfUser({ user: null, status: AddrConfUserStatus.Unauthorized });
    }
    return () => { isMounted = false; };
      // eslint-disable-next-line
  }, [addrConfUserId]);

  const reloadAddrConfUser = () => {
    setAddrConfUserId(`${addrConfUserId} `);
  };

  return { addrConfUser: addrConfUser.user, addrConfUserLoadingState: addrConfUser.status, reloadAddrConfUser };
};
